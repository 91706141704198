.housemate-post-tile {
  border: 1px solid $gray-1;
  padding: 16px;

  @include xs {
    padding: 24px;
  }

  &.selected {
    border: 2px solid $black;
    padding: 15px;

    @include xs {
      padding: 23px;
    }
  }

  &.is-selectable {
    cursor: pointer;
  }
}
