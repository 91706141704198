$size: 16px;

.play-button {
  position: relative;
  width: $size;
  height: $size;

  .arrow {
    position: absolute;
    top: 0;
    transition: width $transition-duration $ease-in-out;
    width: 50%;
    height: 100%;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      transition: border $transition-duration $ease-in-out, height $transition-duration $ease-in-out;
      border-style: solid;
      border-width: $size/2 $size;
      border-color: transparent transparent transparent $pacific;
      width: 0;
      height: 0;
    }
  }

  .arrow-left {
    left: 0;

    &::after {
      left: 0;
    }
  }

  .arrow-right {
    right: 0;

    &::after {
      right: -$size;
    }
  }

  &.active {
    .arrow {
      width: 40%;

      &::after {
        border-width: 0 $size;
        height: $size;
      }
    }
  }
}
