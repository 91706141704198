$switch-margin: 4px;
$switch-handle: 16px;

.switch {
  display: inline-block;
  position: relative;
  background-color: $gray-1;
  border-radius: ($switch-handle + $switch-margin * 2) * .5;
  transition: background-color $cubic-bezier $transition-duration;

  &.is-animated .switch-handle {
    transition: margin $cubic-bezier $transition-duration;
  }

  &.checked {
    background-color: $pacific;
  }
}

.switch-checkbox {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 1;
  cursor: pointer;

  &:checked + .switch-handle {
    margin: $switch-margin $switch-margin $switch-margin $switch-handle * 2.5;

    &::after {
      content: 'ON';
      left: $switch-margin * 3;
    }
  }
}

.switch-handle {
  display: block;
  width: $switch-handle;
  height: $switch-handle;
  margin: $switch-margin $switch-handle * 2.5 $switch-margin $switch-margin;
  background-color: $white;
  border-radius: 50%;

  &::after {
    content: 'OFF';
    position: absolute;
    top: 0;
    left: $switch-handle + $switch-margin * 2;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1px;
    color: $white;
    transition: left $cubic-bezier $transition-duration;
  }
}
