.setup-wrapper {
  .speech-bubble {
    position: relative;
    border: 2px solid $black;
    border-radius: 0 20px 20px 20px;
    padding: $default-spacing * 2;

    &::after,
    &::before {
      content: '';
      position: absolute;
      border-width: 44px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
    }

    &::before {
      left: -44px;
      top: -2px;
      border-color: transparent $black;
      margin-top: 0;
    }

    &::after {
      left: -40px;
      top: 2px;
      border-color: transparent $white;
      margin-top: -2px;
    }
    @include xs {
      border-radius: 20px;

      &::after,
      &::before {
        left: auto;
        border-width: 24px;
        transform: rotate(90deg);
      }

      &::before {
        top: -26px;
        right: 50%;
      }

      &::after {
        top: -20px;
        right: calc(50% + 2px);
      }
    }
  }
}
