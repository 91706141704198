.swiper-container {
  @include gt-xs {
    &:hover .swiper-button-next, &:hover .swiper-button-prev {
      display: block !important;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    background: none;
    outline: none;
    user-select: none;
    @include gt-xs {
      display: none !important;
    }

    &:after {
      content: '';
    }

    &.hover-grow--sm {
      width: $default-spacing * 2;
      height: $default-spacing * 2;

      &:hover, &:focus {
        width: $default-spacing * 2 + $default-spacing * .25;
        height: $default-spacing * 2 + $default-spacing * .25;
      }
    }

    &.hover-grow--lg {
      width: $default-spacing * 3;
      height: $default-spacing * 3;

      &:hover, &:focus {
        width: $default-spacing * 3 + $default-spacing * .5;
        height: $default-spacing * 3 + $default-spacing * .5;
      }
    }

    &.hover-grow--sm, &.hover-grow--lg {
      transition: width $transition-duration-sm $cubic-bezier, height $transition-duration-sm $cubic-bezier;
    }
  }
}

.listing-carousel-loader {
  .animated-background {
    position: relative;
    width: 100%;
    height: 400px;
    background: $gray-3;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    animation: placeholderShimmer 2.00s linear infinite forwards;
    @include gt-xs {
      height: 650px;
    }
  }
}

.listing-carousel-container {
  position: relative;
  height: 400px;

  @include gt-xs {
    height: 650px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include gt-xs {
      width: 60%;
    }

    img {
      height: 100%;
      max-width: 100%;
      min-height: 400px;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;

      @include gt-xs {
        height: 100%;
        min-height: 650px;
      }
    }
  }

  .swiper-slide:nth-child(2n) {
    @include gt-xs {
      width: 40%;
    }
  }

  .swiper-slide:nth-child(3n) {
    @include gt-xs {
      width: 20%;
    }
  }
}

.gallery {
  position: relative;

  .item {
    vertical-align: top;
    display: inline-block;
    transition: transform $transition-duration $cubic-bezier;

    @include xs {
      width: 50%;
    }

    @include xs-sm {
      width: 33.33%;
    }

    @include gt-sm {
      width: 16.66%;
    }
  }

  $city-count: 8;
  @for $i from 0 through ($city-count - 2) {
    &.active-#{$i} .item {
      transform: translateX(-100% * $i);
    }
  }

  .gallery-control {
    position: absolute;
    top: 46%;

    &.prev {
      left: -$default-spacing;

      @include gt-md {
        left: -$default-spacing * 3.5;
      }

      @include gt-lg {
        left: -$default-spacing * 5;
      }
    }

    &.next {
      right: -$default-spacing;

      @include gt-md {
        right: -$default-spacing * 3.5;
      }

      @include gt-lg {
        right: -$default-spacing * 5;
      }
    }
  }

  &.active-0 .gallery-control.prev {
    display: none;
  }

  @include xs {
    &.active-6 .gallery-control.next {
      display: none;
    }
  }

  @include xs-sm {
    &.active-5 .gallery-control.next {
      display: none;
    }
  }

  @include gt-sm {
    &.active-2 .gallery-control.next {
      display: none;
    }
  }
}
