.housemate-hero-illo {
  @include sm {
    max-width: 368px;
  }
  @include gt-sm {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; // Put behind other components
  }

  > div {
    @include gt-sm {
      position: absolute;
      top: 50%;
      left: 608px;
      width: 544px;
      transform: translateY(-50%);
    }
    @include gt-md {
      left: 48%;
      width: 672px;
    }
    @include gt-lg {
      left: 50%;
    }
  }
}
