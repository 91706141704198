.listing-tile {
  .listing-description-wrapper {
    position: relative;
    overflow: hidden;

    .listing-description {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
}

.tile-image {
  overflow: hidden;
  z-index: 0;
  cursor: pointer;

  img::before {
    content: ' ';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url($image-url + 'general/listing-broken-image.jpg');
    background-size: cover;
    background-position: center;
  }
}

.favorite-icon {
  stroke-width: 2px;
  stroke: $white;
  fill: transparent;

  &.filled path {
    fill: $pacific;
  }

  &.pacific-stroke {
    stroke: $pacific;
  }
}

.diagonal-separator {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: $gray-2;
    transform: skewX(-20deg);
  }
}

.pin {
  position: absolute;
  width: 0px;
  height: 0px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: $white;
  border: 14px solid transparent;
  border-top: 23px solid $white;

  &.pin--tile {
    top: 15px;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      border-top-color: $black;

      &::after {
        background-color: $black;
      }
    }
  }

  &::after {
    position: absolute;
    content: '';
    top: -44px;
    left: -15px;
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 50%;
  }

  span {
    position: absolute;
    top: -24px;
    left: -13px;
    width: 0px;
    height: 0px;
    border: 13px solid transparent;
    border-top: 22px solid $pacific;
    z-index: 1;

    &::after {
      position: absolute;
      content: attr(data-num);
      top: -41px;
      left: -14px;
      width: 28px;
      height: 28px;
      background-color: $pacific;
      border-radius: 50%;
    }
  }
}
