html, body, #app, .page-wrapper, .app-wrapper {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

body {
  position: relative;
  color: $black;
  background-color: $white;
  margin: 0;
  padding: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;
}

::selection, ::-moz-selection {
  background: $light-blue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: $default-spacing * .5 0 0 0;
}

button,
input,
select {
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

mark {
  display: inline-block;
  background-color: $light-blue;
  color: $black;
}

ul li {
  margin-top: $default-spacing * .5;
  list-style-image: url($image-url + 'svg/bullet.svg');

  &.default-list-style {
    list-style: initial;
  }
}

ul, ol {
  padding-left: $default-spacing * 1.5;
}

ol li {
  padding-left: $default-spacing * .5;
}

.list-style-none {
  list-style: none;
}

.list-style-upper-alpha {
  list-style: upper-alpha;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-transition {
  display: inline-block;
  transition: transform $transition-duration $cubic-bezier-button;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.white-space-wrap {
  white-space: pre-line;
  word-break: break-word;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.box-shadow--spread {
  box-shadow: $box-shadow--spread;
}

.box-shadow--spread-lg {
  box-shadow: $box-shadow--spread-lg;
}

.box-shadow--top {
  box-shadow: $box-shadow--top;
}

.box-shadow--top-lt-md {
  @include sm {
    box-shadow: $box-shadow--top;
  }
}

.box-shadow-thick {
  box-shadow: $box-shadow-thick;
}

.box-shadow-thick-opaque {
  box-shadow: $box-shadow-thick-opaque;
}

.no-select {
  user-select: none;
}

.tooltip-icon {
  position: relative;
  top: -2px;
  width: 16px;
  height: 16px;
  cursor: default;
  font-size: 13px;
  line-height: 13px;
}

.unread-dot {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -6px;
    width: 6px;
    height: 6px;
    background-color: $red;
    border-radius: 50%;
  }
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
}
