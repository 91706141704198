.layout-row {
  display: flex;
}

.layout-column {
  display: flex;
  flex-direction: column;
}

.layout-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.layout-wrap {
  flex-wrap: wrap;
}

.layout-align,
.layout-align-start-stretch {
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

.layout-align,
.layout-align-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-start,
.layout-align-start-stretch {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.layout-align-center,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-start,
.layout-align-center-stretch {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.layout-align-end,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-start,
.layout-align-end-stretch {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-end,
.layout-align-space-around-start,
.layout-align-space-around-stretch {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-end,
.layout-align-space-between-start,
.layout-align-space-between-stretch {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-around-start,
.layout-align-space-between-start,
.layout-align-start-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-around-center,
.layout-align-space-between-center,
.layout-align-start-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  max-width: 100%;
}

.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-around-center > *,
.layout-align-space-between-center > *,
.layout-align-start-center > * {
  max-width: 100%;
}

.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-around-end,
.layout-align-space-between-end,
.layout-align-start-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-around-stretch,
.layout-align-space-between-stretch,
.layout-align-start-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  align-content: stretch;
}

.flex {
  -webkit-flex: 1;
  flex: 1;
}

.flex-3 {
  -webkit-flex: 3;
  flex: 3;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.layout-row > .flex-0 {
  min-width: 0;
}

.layout-row > .flex-5 {
  max-width: 5%;
  max-height: 100%;
}

.layout-row > .flex-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-10 {
  max-width: 10%;
  max-height: 100%;
}

.layout-row > .flex-10 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-15 {
  max-width: 15%;
  max-height: 100%;
}

.layout-row > .flex-15 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-20 {
  max-width: 20%;
  max-height: 100%;
}

.layout-row > .flex-20 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-25 {
  max-width: 25%;
  max-height: 100%;
}

.layout-row > .flex-25 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-30 {
  max-width: 30%;
  max-height: 100%;
}

.layout-row > .flex-30 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-33 {
  max-width: 33%;
  max-height: 100%;
}

.layout-row > .flex-33 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-35 {
  max-width: 35%;
  max-height: 100%;
}

.layout-row > .flex-35 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-40 {
  max-width: 40%;
  max-height: 100%;
}

.layout-row > .flex-40 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-45 {
  max-width: 45%;
  max-height: 100%;
}

.layout-row > .flex-45 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-50 {
  max-width: 50%;
  max-height: 100%;
}

.layout-row > .flex-50 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-55 {
  max-width: 55%;
  max-height: 100%;
}

.layout-row > .flex-55 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-60 {
  max-width: 60%;
  max-height: 100%;
}

.layout-row > .flex-60 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-65 {
  max-width: 65%;
  max-height: 100%;
}

.layout-row > .flex-65 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-70 {
  max-width: 70%;
  max-height: 100%;
}

.layout-row > .flex-70 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-75 {
  max-width: 75%;
  max-height: 100%;
}

.layout-row > .flex-75 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-75 {
  max-width: 75%;
  max-height: 100%;
}

.layout-row > .flex-75 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-80 {
  max-width: 80%;
  max-height: 100%;
}

.layout-row > .flex-80 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-85 {
  max-width: 85%;
  max-height: 100%;
}

.layout-row > .flex-85 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-90 {
  max-width: 90%;
  max-height: 100%;
}

.layout-row > .flex-90 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
}

.layout-row > .flex-95 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
}

.layout-row > .flex-100 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
}

.layout-row > .flex-33 {
  -webkit-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
}

.layout-row > .flex-66 {
  -webkit-flex: 1 1 66.66%;
  flex: 1 1 66.66%;
}

.layout-row > .flex-33 {
  max-width: 33.33%;
}

.layout-row > .flex-33,
.layout-row > .flex-66 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  flex: 1 1 100%;
  max-height: 100%;
}

.layout-row > .flex-66 {
  max-width: 66.66%;
}

@include xs {
  .layout-xs-wrap {
    flex-wrap: wrap;
  }

  .flex-xs-45,
  .layout-row > .flex-xs-45 {
    max-width: 45%;
    max-height: 100%;
  }

  .layout-row > .flex-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-45 {
    max-width: 45%;
    max-height: 100%;
  }

  .layout-xs-row > .flex-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-xs-50,
  .layout-row > .flex-xs-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .layout-row > .flex-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .layout-xs-row > .flex-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-xs-100,
  .layout-row > .flex-xs-100 {
    max-width: 100%;
    max-height: 100%;
  }

  .layout-row > .flex-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-xs-row > .flex-xs-100 {
    max-width: 100%;
    max-height: 100%;
  }

  .layout-xs-row > .flex-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-align-xs-space-between-end {
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
  }
}

@include gt-xs {
  .layout-gt-xs-row {
    display: flex;
  }

  .layout-gt-xs-wrap {
    flex-wrap: wrap;
  }

  .layout-align-gt-xs-start-stretch {
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
  }

  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-start-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-start-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-around-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-start-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-start-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-around-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-start-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-gt-xs {
    -webkit-flex: 1;
    flex: 1;
  }

  .flex-gt-xs,
  .flex-gt-xs-grow {
    -webkit-box-flex: 1;
    box-sizing: border-box;
  }

  .flex-gt-xs-grow {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }

  .flex-gt-xs-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-xs-0,
  .layout-row > .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-0 {
    min-width: 0;
  }

  .layout-gt-xs-row > .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-0 {
    max-width: 0;
    max-height: 100%;
    min-width: 0;
  }

  .flex-gt-xs-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .layout-row > .flex-gt-xs-5 {
    max-width: 5%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-5 {
    max-width: 5%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-10 {
    max-width: 10%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-10 {
    max-width: 10%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-15,
  .layout-row > .flex-gt-xs-15 {
    max-width: 15%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-15 {
    max-width: 15%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-20,
  .layout-row > .flex-gt-xs-20 {
    max-width: 20%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-20 {
    max-width: 20%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-25,
  .layout-row > .flex-gt-xs-25 {
    max-width: 25%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-25 {
    max-width: 25%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-30,
  .layout-row > .flex-gt-xs-30 {
    max-width: 30%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-30 {
    max-width: 30%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-35,
  .layout-row > .flex-gt-xs-35 {
    max-width: 35%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-35 {
    max-width: 35%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-40,
  .layout-row > .flex-gt-xs-40 {
    max-width: 40%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-40 {
    max-width: 40%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-45,
  .layout-row > .flex-gt-xs-45 {
    max-width: 45%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-45 {
    max-width: 45%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-50,
  .layout-row > .flex-gt-xs-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-55,
  .layout-row > .flex-gt-xs-55 {
    max-width: 55%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-55 {
    max-width: 55%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-60,
  .layout-row > .flex-gt-xs-60 {
    max-width: 60%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-60 {
    max-width: 60%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-65,
  .layout-row > .flex-gt-xs-65 {
    max-width: 65%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-xs-65 {
    max-width: 65%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }

  .layout-gt-xs-row > .flex-gt-xs-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }

  .layout-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33 {
    max-width: 33.33%;
  }

  .layout-row > .flex-gt-xs-33,
  .layout-gt-xs-row > .flex-gt-xs-33,
  .layout-gt-xs-row > .flex-gt-xs-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-xs-66 {
    max-width: 66.66%;
  }

  .flex-gt-xs-70,
  .layout-row > .flex-gt-xs-70 {
    max-width: 70%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-70 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-75,
  .layout-row > .flex-gt-xs-75 {
    max-width: 75%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-80,
  .layout-row > .flex-gt-xs-80 {
    max-width: 80%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-xs-100,
  .layout-row > .flex-gt-xs-100 {
    max-width: 100%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-xs-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-order-gt-xs-2 {
    order: 2;
  }
}

@include xs-sm {
  .flex-sm-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .flex-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-sm-33 {
    max-width: 33%;
    max-height: 100%;
  }

  .flex-sm-33 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
};

@include sm {
  .layout-sm-row {
    display: flex;
  }

  .layout-row > .flex-sm-40 {
    max-width: 40%;
    max-height: 100%;
  }

  .layout-row > .flex-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-sm-row > .flex-sm-40 {
    max-width: 40%;
    max-height: 100%;
  }

  .layout-sm-row > .flex-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-sm-100 {
    max-width: 100%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-sm-100 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
};

@include gt-sm {
  .layout-gt-sm-row {
    display: flex;
  }

  .layout-gt-sm-wrap {
    flex-wrap: wrap;
  }

  .layout-align-gt-sm-start-stretch {
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
  }

  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-stretch {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-stretch {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-stretch {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-stretch {
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }

  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-stretch {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-start-start {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }

  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-start-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-content: center;
    align-content: center;
    max-width: 100%;
  }

  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-around-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-start-center > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-start-end {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
  }

  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-around-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-start-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
  }

  .flex-gt-sm {
    -webkit-flex: 1;
    flex: 1;
  }

  .flex-gt-sm,
  .flex-gt-sm-grow {
    -webkit-box-flex: 1;
    box-sizing: border-box;
  }

  .flex-gt-sm-grow {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }

  .flex-gt-sm-initial {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-none {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-noshrink {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-nogrow {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  .flex-gt-sm-0,
  .layout-row > .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-0 {
    min-width: 0;
  }

  .layout-gt-sm-row > .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-0 {
    max-width: 0;
    max-height: 100%;
    min-width: 0;
  }

  .flex-gt-sm-0 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0;
    box-sizing: border-box;
    min-height: 0;
  }

  .layout-row > .flex-gt-sm-5 {
    max-width: 5%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-5 {
    max-width: 5%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-5 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-10 {
    max-width: 10%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-10 {
    max-width: 10%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-10 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-15,
  .layout-row > .flex-gt-sm-15 {
    max-width: 15%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-xs-row > .flex-gt-sm-15,
  .layout-gt-sm-row > .flex-gt-sm-15 {
    max-width: 15%;
    max-height: 100%;
  }

  .layout-gt-xs-row > .flex-gt-sm-15,
  .layout-gt-sm-row > .flex-gt-sm-15 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-sm-16 {
    max-width: 16.66%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-16 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-20,
  .layout-row > .flex-gt-sm-20 {
    max-width: 20%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-20 {
    max-width: 20%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-20 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-25,
  .layout-row > .flex-gt-sm-25 {
    max-width: 25%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-25,
  .layout-gt-xs-row > .flex-gt-sm-25,
  .layout-gt-sm-row > .flex-gt-sm-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-25 {
    max-width: 25%;
    max-height: 100%;
  }

  .flex-gt-sm-30,
  .layout-row > .flex-gt-sm-30 {
    max-width: 30%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-30 {
    max-width: 30%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-30 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-35,
  .layout-row > .flex-gt-sm-35 {
    max-width: 35%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-35 {
    max-width: 35%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-35 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-40,
  .layout-row > .flex-gt-sm-40 {
    max-width: 40%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-40 {
    max-width: 40%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-40 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-45,
  .layout-row > .flex-gt-sm-45 {
    max-width: 45%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-45 {
    max-width: 45%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-45 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-50,
  .layout-row > .flex-gt-sm-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-55,
  .layout-row > .flex-gt-sm-55 {
    max-width: 55%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-55 {
    max-width: 55%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-55 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-60,
  .layout-row > .flex-gt-sm-60 {
    max-width: 60%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-60 {
    max-width: 60%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .flex-gt-sm-65,
  .layout-row > .flex-gt-sm-65 {
    max-width: 65%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-65 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-65 {
    max-width: 65%;
    max-height: 100%;
  }

  .flex-gt-sm-75,
  .layout-row > .flex-gt-sm-75 {
    max-width: 75%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-sm-75 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-sm-75 {
    max-width: 65%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-33,
  .layout-row > .flex-gt-sm-33 {
    -webkit-flex: 1 1 33.33%;
    flex: 1 1 33.33%;
  }

  .layout-gt-sm-row > .flex-gt-sm-66,
  .layout-row > .flex-gt-sm-66 {
    -webkit-flex: 1 1 66.66%;
    flex: 1 1 66.66%;
  }

  .layout-gt-sm-row > .flex-gt-sm-33,
  .layout-row > .flex-gt-sm-33 {
    max-width: 33.33%;
  }

  .layout-gt-sm-row > .flex-gt-sm-33,
  .layout-gt-sm-row > .flex-gt-sm-66,
  .layout-row > .flex-gt-sm-33,
  .layout-row > .flex-gt-sm-66 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-sm-66,
  .layout-row > .flex-gt-sm-66 {
    max-width: 66.66%;
  }

  .flex-order-gt-sm-2 {
    order: 2;
  }
}

@include sm-md {
  .layout-gt-sm-row > .flex-md-60 {
    max-width: 60%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-md-60 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
};

@include gt-md {
  .layout-gt-md-row {
    display: flex;
  }

  .layout-row > .flex-gt-md-16 {
    max-width: 16.66%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-md-25 {
    max-width: 25%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-md-25 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-gt-sm-row > .flex-gt-md-50 {
    max-width: 50%;
    max-height: 100%;
  }

  .layout-gt-sm-row > .flex-gt-md-50 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .layout-row > .flex-gt-md-80 {
    max-width: 80%;
    max-height: 100%;
  }

  .layout-row > .flex-gt-md-80 {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    box-sizing: border-box;
  }
};
