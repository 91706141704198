.calendar-day {
  text-align: center;
  font-size: 14px;
  width: 100%;
  color: $black;
  outline: none;
  text-decoration: none;

  .calendar-event-bar {
    margin-top: 2px;
    height: 3px;
    width: 100%;
    background: $pacific;
    cursor: pointer;

    &.past-event {
      background: $gray-1;
    }
  }

  &.disabled, &.booked {
    color: $gray-1;
    background-color: $gray-3;
    background: repeating-linear-gradient(-45deg, $gray-3, $gray-3 3px, $gray-2 3px, $gray-2 4px) !important;
  }

  &.selected:not(.blank) {
    color: $white;
    background: $hue-2 !important;
  }

  &.hovered:not(.blank),
  &:hover:not(.selected):not(.blank):not(.disabled) {
    color: $black;
    background: $light-blue;
    cursor: pointer;
  }
}

.calendar-day--lg {
  position: relative;
  text-align: left;
  font-size: 14px;
  width: 100%;
  height: 80px;
  color: $black;
  outline: none;
  @include gt-xs {
    height: 96px;
  }
  @include gt-sm {
    height: 112px;
  }
  @include gt-md {
    height: 144px;
  }

  .calendar-event-bar {
    position: absolute;
    height: 22px;
    width: 100%;
    background: $pacific;
    color: $white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    cursor: pointer;

    &.hovered,
    &:hover {
      background: $black;
    }

    &.m-top-block--2 {
      top: 54px;
    }

    &.m-top-block--3 {
      top: 82px;
    }

    &.m-top-block--4 {
      top: 110px;
    }
  }

  &.blank {
    background: $white !important;
  }

  &.disabled, &.booked {
    color: $gray-1;
    background-color: $gray-3 !important;
    background: repeating-linear-gradient(-45deg, $gray-3, $gray-3 8px, $gray-2 8px, $gray-2 10px) !important;
  }

  &:not(.disabled) {
    background: $white;
  }

  &.selected:not(.blank):not(.disabled) {
    color: $white;
    background: $hue-2 !important;
  }

  &.hovered:not(.blank):not(.disabled),
  &:hover:not(.blank):not(.disabled) {
    background: $light-blue;
    cursor: pointer;
  }
}

.calendar-loading-overlay {
  padding-top: 200px;
}
