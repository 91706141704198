input[type="range"],
input,
input:matches([type="password"], [type="search"]),
textarea {
  appearance: none;
}

input {
  height: $input-height;
  min-height: $input-height;
  // Fixes mobile IOS weird border shadows: https://zomigi.com/blog/bug-fixes-for-removing-the-inner-shadow-on-ios-inputs/
  background-image:-webkit-gradient(linear, 0% 0%, 0% 100%, from(hsla(0,0%,100%,0)), to(hsla(0,0%,100%,0)));
  background-image:-webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0));
}

.input-wrapper {
  &:not(.checkbox):not(.radio) label {
    text-align: left;

    &:disabled {
      color: $gray-1;
      background-color: $gray-3;
    }
  }

  &:not(.checkbox):not(.radio) input:disabled {
    color: $gray-1;
    background-color: $gray-3;
  }

  label.required::after {
    content: " *";
    color: $red;
  }

  textarea {
    display: block;
    min-height: $input-height;

    &.no-resize {
      resize: none;
    }
  }

  input,
  textarea {
    width: 100%;
    resize: none;
    padding: $default-spacing * .5 $default-spacing * .75 $default-spacing * .625 $default-spacing * .75;
    background-color: $white;
    border-style: solid;
    box-sizing: border-box;
    border: $input-border-width solid $gray-2;
    border-radius: $input-border-radius;

    &:not(.date-picker) {
      &:focus {
        border-color: $black;
        outline: none;
      }
    }

    &.input-with-icon {
      padding-left: $default-spacing * 2.5;
    }

    &.input-with-clear-button {
      padding-right: $default-spacing * 2.5;

      &:focus ~ .input-clear-button {
        opacity: 1;
      }

      ~ .input-clear-button {
        opacity: 0;
      }
    }

    @include placeholder-color {
      color: $gray-1;
    }
  }

  // Custom phone number input
  .phone-number-input {
    &.with-area-code {
      border-radius: 0 12px 12px 0 !important;
    }
  }
  .area-code {
    padding: 10px 12px;
    border-radius: 12px 0 0 12px;
  }

  &.error input,
  &.error textarea {
    border-color: $red;
    background-color: $light-pink;

    @include placeholder-color {
      color: $red;
    }
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .pseudo-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  &.format--currency .pseudo-wrapper {
    &::before {
      content: '$';
      position: absolute;
      top: 50%;
      left: $default-spacing;
      transform: translateY(-50%);
    }

    input {
      padding-left: $default-spacing * 2;
    }
  }

  &.format--currency-usd .pseudo-wrapper {
    &::after {
      content: 'USD';
      position: absolute;
      top: 50%;
      right: $default-spacing;
      transform: translateY(-50%);
    }

    input {
      padding-right: $default-spacing * 3.5;
    }
  }

  &.format--percent .pseudo-wrapper {
    position: relative;

    &::after {
      content: '%';
      position: absolute;
      top: 50%;
      right: $default-spacing;
      transform: translateY(-50%);
    }

    input {
      padding-right: $default-spacing * 2;
    }
  }

  &.format--code .pseudo-wrapper {
    margin: 0 $default-spacing * .25;

    input {
      width: 40px;
      font-size: 30px;
      text-align: center;
      border-width: 0 0 1px 0;
      border: 2px solid $black;
      border-radius: 4px;
      padding: $default-spacing * .375 $default-spacing * .5;

      &:focus {
        border-color: $pacific;
      }
    }
  }

  &.h3-input {
    .pseudo-wrapper {
      &::after, &::before {
        font-weight: 600;
        font-size: 24px;
      }
    }
    input {
      padding: $default-spacing * .5 - 1 $default-spacing * .75 $default-spacing * .625 - 1 $default-spacing * .75;
      font-size: 24px;
      line-height: 1.25;
      letter-spacing: -.67px;
      font-weight: 600;
    }
  }
}

.input-wrapper.checkbox {
  position: relative;
  min-width: $checkbox-size;
  min-height: 26px;

  &.no-label input,
  &.no-label label::before {
    top: 2px;
  }

  input {
    position: absolute;
    top: ($body-line-height - ($checkbox-size + $checkbox-border-width * 2)) / 2;
    left: 0;
    height: $checkbox-size + $checkbox-border-width * 2;
    width: $checkbox-size + $checkbox-border-width * 2;
    margin: 0;
    opacity: 0;
    z-index: 98;

    & + label::after {
      content: none;
    }

    &:checked + label::after {
      content: "";
    }

    &:checked + label::before {
      background-color: $pacific;
    }

    &:not(:disabled),
    &:not(:disabled) + label {
      cursor: pointer;
    }

    &:disabled + label::before {
      border-color: $gray-2;
    }

    &:disabled:checked + label::before {
      background-color: $gray-2;
    }

    &:disabled:not(:checked) + label {
      color: $gray-2;
    }
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: $checkbox-size + $checkbox-text-spacing;
    font-size: 16px;
    font-weight: 500;

    &:empty {
      position: absolute;

      &::after {
        top: 8px;
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      display: inline-block;
    }

    &::before {
      top: 4px;
      left: 0px;
      width: $checkbox-size;
      height: $checkbox-size;
      border: $checkbox-border-width solid $pacific;
    }

    &::after {
      left: 5px;
      top: 10px;
      width: 9px;
      height: 4px;
      border-left: $checkbox-border-width solid $white;
      border-bottom: $checkbox-border-width solid $white;
      transform: rotate(-45deg);
    }
  }

  .checkbox-error {
    padding-left: $checkbox-size + $checkbox-text-spacing;
  }
}

.input-wrapper.radio {
  input:checked,
  input:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  input:checked + label,
  input:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;

    &::before {
      content: '';
      position: absolute;
      left: -$checkbox-border-width;
      top: 4px;
      width: $checkbox-size;
      height: $checkbox-size;
      border: $checkbox-border-width solid $pacific;
      border-radius: 100%;
      background: $white;
    }
  }

  input:checked + label::after,
  input:not(:checked) + label::after {
    content: '';
    width: $checkbox-size - ($checkbox-border-width * 2);
    height: $checkbox-size - ($checkbox-border-width * 2);
    background: $pacific;
    position: absolute;
    top: $checkbox-border-width * 4;
    left: $checkbox-border-width;
    border-radius: 100%;
    transition: opacity $transition-duration $cubic-bezier, transform $transition-duration $cubic-bezier;
  }

  input:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0);
  }

  input:checked + label::after {
    opacity: 1;
    transform: scale(1);
  }
}
