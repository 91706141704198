.body-text,
body,
button,
input,
select,
textarea,
.pac-item {
  font-family: Soleil, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: $body-line-height;
  letter-spacing: 0;
}

h1,
h2,
h3 {
  font-weight: 800;
}

h4,
h5,
h6,
label {
  font-weight: 700;
}

.h1,
h1 {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: -1px;
  @include gt-xs {
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1.2px;
  }

  &.huge {
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1.2px;
    @include gt-xs {
      font-size: 54px;
			line-height: 60px;
      letter-spacing: -1.5px;
    }
    @include gt-sm {
      font-size: 60px;
			line-height: 64px;
      letter-spacing: -2px;
    }
  }
}

.h2,
h2 {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -.8px;
  @include gt-xs {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -1px;
  }
}

.h3,
h3 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -.5px;
}

.h4,
h4 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.h5,
h5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
}

.h6,
h6,
.input-wrapper:not(.checkbox):not(.radio) label,
.input-wrapper.radio .label,
.select-wrapper label,
.custom-select-wrapper label {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .3px;
}

a {
  font-weight: 700;

  &.meta {
    line-height: 16px;
  }
}

.intro {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.1px;
}

.meta {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.roman {
  font-weight: 400;
}

.bold {
  color: $black;
  font-weight: 700;
}

.strong {
  font-weight: 700;
}

.title-case::first-letter {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.line-height-normal {
  line-height: normal;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis-xs {
  @include xs {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.ellipsis-two-line {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  height: 48px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
}

.nowrap {
  white-space: nowrap;
}
