$pane-width: 400px;

.listing-container {
  width: 100%;
  @include gt-sm {
    width: calc(100% - #{$pane-width});
  }

  .amenity {
    width: 50%;
    @include gt-xs {
      width: 33%;
    }
    @include gt-md {
      width: 25%;
    }
  }
}

.commute-tab {
  padding: 12px $default-spacing;
  border: 1px solid $gray-2;
  border-width: 1px 0 0 1px;
  transition: color $transition-duration $cubic-bezier-button;

  &:first-child {
    border-top-left-radius: $border-radius-lg;
  }

  &:last-child {
    border-top-right-radius: $border-radius-lg;
    border-right-width: 1px;
  }

  &.active {
    color: $white;
    background-color: $hue-0;
  }

  &:hover:not(.active) {
    background-color: $gray-3;
  }
}
