.mentions, .mentions__control, .mentions__highlighter {
  height: $input-height;
}

.mentions__input {
  width: 100%;
  resize: none;
  padding: $default-spacing * 0.5 $default-spacing * 0.75 $default-spacing * 0.625 $default-spacing * 0.75;
  background-color: $white;
  border-style: solid;
  box-sizing: border-box;
  border: $input-border-width solid $gray-2;
  border-radius: $input-border-radius;

  &:focus:not(.date-picker) {
    border-color: $black;
    outline: none;
  }
}

.mentions__suggestions {
  width: 100%;
  margin-top: $input-height !important;
  border: solid 1px $gray-2;
  border-radius: $input-border-radius;
  box-shadow: $box-shadow;
  z-index: 15;
  overflow: hidden;

  .mentions__suggestions__item {
    color: $black;
    cursor: pointer;
    background-color: $white;
    margin: 0;
    padding: $default-spacing * 0.5 $default-spacing * 0.75;

    &.mentions__suggestions__item--disabled {
      background-color: $white;
      color: $gray-1;

      img {
        opacity: 0.3;
      }
    }

    &:hover:not(.mentions__suggestions__item--disabled) {
      background-color: $gray-3;
    }

    .mentions__suggestions__item--focused {
      font-weight: 700;
    }

    .mentions__suggestions__item__display {
      margin-left: $default-spacing * 0.5;
      margin-top: $default-spacing * 0.125;
    }
  }
}
