$testimonial-width: 288px;
$testimonial-items: 8;
@keyframes scrollRight {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(calc(#{-$testimonial-width * $testimonial-items}), 0, 0);
  }
}

.scroller {
  position: relative;
  overflow: hidden;

  .scroll-track {
    animation: scrollRight 80s linear infinite;
    display: flex;
    width: $testimonial-width * $testimonial-items * 2; // * 2 is the secret

    &.paused {
      animation-play-state: paused;
    }
  }

  .scroll {
    width: $testimonial-width;
    height: 256px;
  }
}
