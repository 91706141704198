// Border Colors
.b--inherit {
  border-color: inherit;
}

.b--red {
  border-color: $red;
}

.b--medium-green {
  border-color: $medium-green;
}

.b--gray-2 {
  border-color: $gray-2;
}

.b--black {
  border-color: $black;
}

.b--pacific {
  border-color: $pacific;
}

.b--pacific-hover:hover {
  border-color: $pacific;
}

// Border thickness
.b-around--xs {
  border-width: 1px;
  border-style: solid;
}

.b-around--xs-gt-xs {
  @include gt-xs {
    border-width: 1px;
    border-style: solid;
  }
}

.b-top--xs {
  border-top-width: 1px;
  border-top-style: solid;
}

.b-top--xs-gt-xs {
  @include gt-xs {
    border-top-width: 1px;
    border-top-style: solid;
  }
}

.b-top--sm {
  border-top-width: 2px;
  border-top-style: solid;
}

.b-right--xs {
  border-right-width: 1px;
  border-right-style: solid;
}

.b-right--sm {
  border-right-width: 2px;
  border-right-style: solid;
}

.b-bottom--xs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.b-bottom--sm {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.b-bottom--xs-gt-sm {
  @include gt-sm {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}

.b-bottom--xs-lt-md {
  @include sm {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}

.b-left--xs {
  border-left-width: 1px;
  border-left-style: solid;
}

.b-left--sm {
  border-left-width: 2px;
  border-left-style: solid;
}

.b-around--sm {
  border-width: 2px;
  border-style: solid;
}

// Border Table
.b-table--calendar {
  .cell {
    border-width: 1px 0 0 1px;
    border-style: solid;

    &:last-child:not(.blank) {
      border-right-width: 1px;
    }

    &.blank {
      border-width: 0;
    }
  }

  &:last-child .cell {
    &.blank {
      border-top-width: 1px;
    }

    &:not(.blank) {
      border-bottom-width: 1px;
      border-right-width: 1px;
      border-left-width: 0;

      &:first-child {
        border-left-width: 1px;
      }
    }
  }
}

.b-table--calendars {
  border-width: 0 1px 1px 1px;
  border-style: solid;

  &:first-child {
    border-top-width: 1px;
  }

  @include gt-xs {
    &:nth-child(even) {
      border-left-width: 0;
    }

    &:nth-child(2) {
      border-top-width: 1px;
    }
  }
}

.b--rounded {
  border-radius: $border-radius-lg;
}

@include gt-sm {
  .b--rounded-gt-sm {
    border-radius: $border-radius-lg;
  }
}

.b--rounded-sm {
  border-radius: $border-radius-sm;
}

.b--rounded-md {
  border-radius: $border-radius-md;
}

.b-top-right--rounded {
  border-top-right-radius: $border-radius-lg;
}

.b-top-left--rounded {
  border-top-left-radius: $border-radius-lg;
}

.b-bottom-right--rounded {
  border-bottom-right-radius: $border-radius-lg;
}

.b-bottom-left--rounded {
  border-bottom-left-radius: $border-radius-lg;
}

.b--round {
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

.b--none {
  border: none;
}

.horizontal-ruler {
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background: $gray-2;
    transition: width $transition-duration $cubic-bezier $transition-delay;
    transform: translateY(-50%);
  }

  &::before {
    text-align: right;
    right: 55%;
  }

  &::after {
    text-align: left;
    left: 55%;
  }

  &.extend-out::after,
  &.extend-out::before {
    width: 0;
  }

  &:not(.extend-out)::after,
  &:not(.extend-out)::before {
    width: 45%;
  }
}
