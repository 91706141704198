.host-hero {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 645' preserveAspectRatio='none'%3E%3Cpath fill='%23E6F1F8' d='M0 717c73.333-5.333 151.333-8 234-8 114.47 0 266.5 29 455 8 216.677-24.14 302 0 470 0 112 0 205.667-4.333 281-13V80H0v637z' transform='translate(0 -80)'/%3E%3C/svg%3E%0A");
  background-position: bottom center;
}

.host-company-logos {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 495' preserveAspectRatio='none'%3E%3Cpath fill='%23F7F7F7' d='M0 5.173s232.882.196 285.168-2.53c79.545-4.148 279.582-1.419 500.654 5.41C1092.472 17.526 1209.77-3.16 1441 .422v488.77c-163.523-6.895-278.27-8.415-344.244-4.556-207.99 12.163-391.312.053-542.646 4.557-234.26 6.972-418.963 6.972-554.11 0V5.173z' transform='translate(0 -1894) translate(0 1894.578)'/%3E%3C/svg%3E");
  background-position: center;
  background-size: 100% 100%;
}

.host-testimonial {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 650' preserveAspectRatio='none'%3E%3Cpath fill='%23FFE1E0' d='M-50 4359.5c63.977-5.667 185.977-8.5 366-8.5 92.005 0 293 19.5 441.43 8.293 148.431-11.206 304.766.253 490.07-8.293 41.788-1.927 122.622.906 242.5 8.5v627.487c-114.09-8.757-217.924-8.753-311.5.013-79.524 7.45-270 12.5-385.5 12.5-111.123 0-313.445-23.772-508.5-12.5-96.795 5.594-208.295 5.59-334.5-.013V4359.5z' transform='translate(0 -4350)'/%3E%3C/svg%3E");
  background-position: center;
  background-size: 100% 100%;
}

.testimonial-large-quote {
  font-size: 126px;
  font-weight: bold;
  line-height: 0.3;
  letter-spacing: -4.8px;
}

.company-comparison-mobile-table .company-comparison-mobile-table-row {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 8fr 7fr 7fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 2px;
}

.renter-type {
  position: absolute;
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-size: 100%;

  &:nth-child(1) {
    top: 21%;
    left: 0;
  }

  &:nth-child(2) {
    top: 0;
    left: 50%;
    transform: translateX(-55%);
    background-size: 90%;
  }

  &:nth-child(3) {
    top: 19%;
    right: 0;
  }

  &:nth-child(4) {
    bottom: 0;
    left: 10%;
  }

  &:nth-child(5) {
    top: 37%;
    left: 50%;
    transform: translateX(-50%);
  }

  &:nth-child(6) {
    bottom: 8%;
    right: 5%;
    background-size: 78%;
  }

  img {
    width: auto;
    @include xs {
      height: 48px;
    }
    @include gt-xs {
      height: 64px;
    }
  }

  h5 {
    max-width: 150px;
  }
}

@for $i from 1 through 6 {
  .renter-type.circle-#{$i} {
    background-image: url($image-url + 'background/circle-' + $i + '.svg');
  }
}
