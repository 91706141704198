.kopa-for-good-hero .img-container {
  position: absolute;
  width: auto;
  @include xs {
    width: 64px;
    height: 64px;
  }
  @include xs-sm {
    width: 96px;
    height: 96px;
  }
  @include gt-sm {
    width: 124px;
    height: 124px;
  }

  $renter-top-pos: 2%, 40%, 82%, 4%, 10%, 48%, 78%, 10%;
  $renter-right-pos: 77%, 2%, 90%, 23%, 90%, 88%, -1%, 4%;
  @for $i from 1 through length($renter-top-pos) {
    &:nth-child(#{$i + 1}) {
      top: nth($renter-top-pos, $i);
      right: nth($renter-right-pos, $i);
      animation: #{float + $i} #{randomNum(5, 8)}s $ease-in-out infinite;
    }
  }
}

.list-your-property {
  background-image: url($image-url + 'background/rectangle-light-blue-6.svg');
  background-position: center center;
}
