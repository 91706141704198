$date-picker-cell-xs: 35px;
$date-picker-cell-gt-xs: 45px;

.date-picker-wrapper {
  min-width: 130px;
  position: relative;

  input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke='%23185C97' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M18.972 21.906H4.936C3.866 21.906 3 21.03 3 19.95V5.756C3 4.677 3.867 3.8 4.936 3.8h14.036c1.068 0 1.935.876 1.935 1.957V19.95c0 1.08-.867 1.956-1.935 1.956zM7.154 1v4.416M16.846 1v4.416M3 9.4h17.907'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right $default-spacing * .5 center;
    background-size: auto 55%;
    padding: 9px 12px 11px 32px;

    @include placeholder-color {
      color: $gray-1;
    }
  }

  &.date-picker-open input {
    background-position: right $default-spacing * .5 center;
    background-size: auto calc(55%);
  }
}

.date-picker-open .date-picker {
  border-color: $black;
  outline: none;
}

.date-picker-popup {
  z-index: 1000;
  top: $input-height + 26px; // account for label + margin/padding
  visibility: hidden;
  opacity: 0;
  border-radius: $input-border-radius;
  margin-bottom: $default-spacing * 2;
  transition: visibility 0s, opacity 0.2s ease-in;
  min-width: 350px; // do not remove - need for fixed day sizing

  &.hide-date-picker-label {
    top: $input-height + $default-spacing * .5; // account for margin/padding
  }

  @include xs {
    min-width: 280px; // do not remove - need for fixed day sizing
  }

  &.date-picker-open {
    visibility: visible;
    opacity: 1;
  }
}

.date-picker-header {
  width: $date-picker-cell-xs;
  min-width: $date-picker-cell-xs;
  text-align: center;
  @include gt-xs {
    min-width: $date-picker-cell-gt-xs;
  }
}

.date-picker-week {
  .date-picker-day {
    width: $date-picker-cell-xs;
    min-width: $date-picker-cell-xs;
    height: $date-picker-cell-xs;
    text-align: center;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $gray-2;
    outline: none;
    @include gt-xs {
      width: $date-picker-cell-gt-xs;
      min-width: $date-picker-cell-gt-xs;
      height: $date-picker-cell-gt-xs;
    }

    &:last-child:not(.blank) {
      border-right-width: 1px;
    }

    &.blank {
      border-width: 0;
    }

    &.disabled {
      color: $gray-0;
      background-color: $gray-3;
      background: repeating-linear-gradient(-45deg, $gray-3, $gray-3 3px, $gray-2 3px, $gray-2 4px);
    }

    &.available {
      color: $black;
      cursor: pointer;

      &.isBetween {
        background: $light-blue !important;
      }

      &:hover,
      &.hovered,
      &.selected {
        background: $hue-2 !important;
      }
    }
  }

  &:last-child .date-picker-day {
    &.blank {
      border-top-width: 1px;
    }

    &:not(.blank) {
      border-bottom-width: 1px;

      &:not(.disabled) {
        + .blank {
          border-left-width: 1px;
        }
      }
    }
  }
}

.rc-tooltip-inner {
  font-family: Soleil, sans-serif;
  max-width: 300px;
}
