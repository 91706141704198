.housemate-search {
  &.is-creating-group-message {
    padding-bottom: 96px;

    .housemate-post-tile {
      &.my-post {
        opacity: $transparent;
        transition: opacity $transition-duration $ease-in-out;
      }
    }
  }
}

.create-group-message {
  background-color: $white;
  border: 1px solid $gray-1;
  bottom: -96px;
  height: 96px;
  position: fixed;
  text-align: center;
  transition: bottom $transition-duration $ease-in-out;
  width: 100%;
  z-index: 100;

  &.active {
    bottom: 0;
  }
}

@include xs {
  .create-group-message {
    bottom: -128px;
    height: 128px;
  }
}
