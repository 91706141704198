.survey {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: $white;
  animation: scaleUpFadeIn $animation-duration * .75 $cubic-bezier 1 forwards;

  .survey-content {
    background-color: $white;
    overflow: visible;

    @include xs {
      margin-top: 128px;
      width: 100%;
      height: 100%;
    }

    @include gt-xs {
      max-width: 75%;
      max-height: 95%;
    }
  }
}

.survey-progress-bar {
  height: 12px;
  &.survey-progress-bar-total {
    width: 100%;
  }

  &.survey-progress-bar-current {
    transition: width 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  }
}
