.uploadcare--dialog, .uploadcare--text, .uploadcare--button, .uploadcare--menu__item, .uploadcare--tip {
  font-family: Soleil, sans-serif;
}

.uploadcare--widget__button, .uploadcare--panel__menu, .uploadcare--draganddrop.uploadcare--dragging .uploadcare--tip {
  display: none;
}

.uploadcare--button {
  cursor: pointer;
}

.uploadcare--button_primary, .uploadcare--tab .welcome-button, .uploadcare--camera__button {
  border: none;
  background-color: $pacific;
  color: $white;

  &:hover, &:focus {
    background-color: $hue-1;
    color: $white;
  }
}

.uploadcare--preview__back, .uploadcare--panel__show-files{
  color: $pacific;
  border: 2px solid $pacific;

  &:hover, &:focus {
    color: $hue-1;
    border-color: $hue-1;
  }
}
