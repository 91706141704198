.dropdown {
  &.force-hide {
    .dropdown, .dropdown-target::after {
      display: none !important;
    }
  }
}

.dropdown-target {
  position: relative;
  border-radius: $border-radius-lg;
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  height: auto;
  top: 60px;
  z-index: 110;
  background: $white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  transition: opacity $transition-duration ease-in;
  position: absolute;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &.pull-right {
    right: 0;
    left: auto;
  }

  &:not(.overflow-visible) {
    overflow: hidden;
  }

  &:not(.fullscreen) {
    position: absolute;
  }

  &.rounded {
    border-radius: $input-border-radius;
  }

  &:not(.custom-width) {
    @include xs {
      width: 100vw;
      max-width: 100%;
    }
  }

  @include xs {
    right: 0;
  }

  @include gt-xs {
    width: auto;
    min-width: $default-spacing * 12;
    left: 0;
  }

  &.miw--auto {
    @include gt-xs {
      min-width: auto;
    }
  }
}
