.map-marker-container {
  font-family: Soleil, sans-serif;
  width: auto;
  height: auto;
  cursor: pointer;
  overflow: visible !important;

  &.marker-active .map-marker, .map-marker:active {
    z-index: 10000 !important;
    z-index: 100000;
    border-color: $raspberry !important;
    background-color: $raspberry !important;

    &::before, &::after {
      border-top-color: $raspberry !important;
    }
  }

  .map-marker {
    font-family: Soleil;
    position: absolute;
    display: inline-block;
    text-align: center;
    max-width: 80px;
    padding: 4px 6px;
    color: $white;
    background-color: $hue-0;
    border: 1px solid $white;
    border-radius: $border-radius-md;
    outline: none;
    transform: translate(-50%, -100%);
    cursor: pointer;

    &:hover {
      box-shadow: none;
    }

    &:hover, &.active {
      z-index: 1;
    }

    svg {
      min-width: 10px;
      min-height: 10px;
      fill: $white;
      margin-left: 2px;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      transition: background-color $transition-duration linear;
    }

    &::before {
      bottom: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $white;
    }

    &::after {
      bottom: -7px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid $hue-0;
    }
  }

  .map-marker-destination {
    display: inline-block;
    transform: translate(-50%, -100%);
  }
}

.marker-info {
  font-family: Soleil;
}
