.ReactVirtualized__Table__headerRow {
  background-color: $gray-3;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: $gray-2;
  border-radius: $border-radius-lg $border-radius-lg 0 0;

  .ReactVirtualized__Table__headerColumn {
    padding: 16px 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    height: inherit;
    outline: none;
    text-transform: none;
    white-space: nowrap;

    &:hover svg:not(.black) {
      color: $gray-1;
    }
  }

}

.table-wrapper {
  &.disable-header .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
    border-radius: $border-radius-lg;
  }

  &:not(.disable-header) .ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
  }
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: $gray-2;
  outline: none;

  // Show border on mobile since there is no header
  @include sm {
    border-top-width: 1px;
  }

  .ReactVirtualized__Table__row {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: $gray-2;

    @include sm {
      padding: $default-spacing * .5 $default-spacing;
    }

    .ReactVirtualized__Table__rowColumn {
      @include sm {
        padding: $default-spacing * .5 0;
        margin: 0;
      }

      @include gt-sm {
        display: flex;
        height: 100%;
        align-items: center;
        padding-left: $default-spacing * .5;
      }
    }

    .mobile-label {
      min-width: 96px;
      max-width: 96px;
      white-space: normal;
    }
  }
}
