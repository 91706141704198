.dialog {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s $ease-out;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $black-transparent;

  &.dialog-open {
    transition: opacity 0.2s ease-in;
    visibility: visible;
    opacity: 1;
  }

  .dialog-content {
    background-color: $white;
    overflow: scroll;
    animation: scaleUpFadeIn $animation-duration * .75 $cubic-bezier 1 forwards;

    @include xs {
      margin: 0 $default-spacing / 2;
      width: 100%;
      height: calc(100% - #{$default-spacing});
      padding: $default-spacing * 2 $default-spacing * 1.5;
      border-radius: $border-radius-lg;
    }

    @include gt-xs {
      border-radius: $border-radius-md;
      max-width: 75%;
      max-height: 95%;
    }

    &.dialog-content--medium {
      max-width: 608px;
      width: 100%;
    }

    &.dialog-content--full-screen {
      border-radius: 0;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
  }
}
