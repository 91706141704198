.mobile-burger-wrapper {
  margin-top: 72px;

  &.mobile-conversation-list-open {
    margin-top: 72px;
    height: 48px;
  }
}

.conversation-list-menu-wrapper {
  .bm-menu-wrap {
    height: calc((var(--mobile-vh, 1vh) * 100) - 72px) !important;
    margin-top: 72px;
    top: 0px;
    transition: all $transition-duration !important;
  }

  &.mobile-conversation-list-open {
    .bm-menu-wrap {
      transition: none !important;
    }
  }

  &.transitioning-to-conversations-list {
    .bm-menu-wrap {
      transition: all $transition-duration !important;
    }
  }
}

.conversation-list {
  max-height: 100%;
  outline: none;

  @include xs {
    width: 100%;
  }

  @include gt-xs {
    @include sm {
      width: 96px;
    }

    @include gt-sm {
      width: 400px;
    }
  }
}

.conversation-information-menu-wrapper {
  .bm-menu-wrap {
    top: 0px;
    margin-top: 72px;
    transition: all $transition-duration !important;
  }

  .bm-cross-button {
    top: 0 !important;
    left: 0 !important;
    height: 48px !important;
    width: 112px !important;

    .bm-cross {
      background: transparent;
    }
  }
}

.bm-burger-button {
  position: relative;
}

.read-receipt {
  transition: bottom $transition-duration $ease-in-out;
}

.protect-yourself-tooltip {
  max-width: 320px;
  position: absolute;
  top: -112px;
  width: auto;
}

.highlights-backdrop {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.highlights-content {
  background: none transparent !important;
}

.highlights {
  border-color: transparent !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  color: transparent !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  z-index: 1500;

  mark {
    color: transparent;
  }
}

.left-menu.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 22px;
}

.right-menu.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 22px;
}

.bm-burger-bars {
  background: $black;
}

.bm-burger-bars-hover {
  background: $pacific;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $gray-1;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: $white
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
