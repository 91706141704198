@function delay($interval, $count, $index) {
  @return ($index * $interval) - ($interval * $count);
}

.loader-wrapper {
  padding: $default-spacing * .5 0;

  .loader {
    display: inline-block;
    background-color: $pacific;
    border-radius: 100%;
    animation-fill-mode: both;
  }

  &.loader--white .loader{
    background-color: $white;
  }

  &.loader--black .loader{
    background-color: $black;
  }

  &.loader--x-small .loader {
    width: $default-spacing * .25;
    height: $default-spacing * .25;
    margin: 1px;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: ballPulseSync .8s delay(0.07s, 3, $i) infinite $ease-in-out;
      }
    }
  }

  &.loader--small .loader {
    width: $default-spacing * .5;
    height: $default-spacing * .5;
    margin: 2px;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: ballPulseSync .6s delay(0.07s, 3, $i) infinite $ease-in-out;
      }
    }
  }

  &.loader--large .loader {
    width: $default-spacing * .75;
    height: $default-spacing * .75;
    margin: 4px;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: ballPulseSyncLarge .6s delay(0.07s, 3, $i) infinite $ease-in-out;
      }
    }
  }
}

.loading-shimmer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: cover;
  animation: placeholderShimmer 1.25s linear infinite forwards;
}
