.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
}

[class*="icon-"] svg {
  pointer-events: none;
  display: block;
}

.icon--8 {
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
}

.icon--10 {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
}

.icon--12 {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

.icon--14 {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
}

.icon--16 {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.icon--18 {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.icon--20 {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.icon--24 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.icon--30 {
  width: 30px;
  height: 30px;
}

.icon--32 {
  width: 32px;
  height: 32px;
}

.icon--36 {
  width: 36px;
  height: 36px;
}

.icon--48 {
  width: 48px;
  height: 48px;

  svg * {
    stroke-width: 2;
  }
}

.icon--64 {
  width: 64px;
  height: 64px;
}

.icon--pacific {
  color: $pacific;
  fill: $pacific;
}

.icon--hue-0 {
  color: $hue-0;
  fill: $hue-0;
}

.icon--hue-2 {
  color: $hue-2;
  fill: $hue-2;
}

.icon--black {
  color: $black;
  fill: $black;

  &.icon--hover:hover {
    color: $gray-0;
  }
}

.icon--gray-1 {
  color: $gray-1;
  fill: $gray-1;
}

.icon--gray-2 {
  color: $gray-2;
  fill: $gray-2;
}

.icon--white {
  color: $white;
  fill: $white;
}

.icon--red {
  color: $red;
  fill: $red;
}

.icon--raspberry {
  color: $raspberry;
  fill: $raspberry;
}

.icon--medium-green {
  color: $medium-green;
}

.icon--facebook {
  fill: $facebook-color;
}

.icon--twitter {
  fill: $twitter-color;
}

.icon--messenger {
  fill: $messenger-color;
}

.icon--linkedin {
  fill: $linkedin-color;
}

.icon--shadow svg {
  filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.3));
}

.icon-stroke--xs g {
  stroke-width: 1px !important;
}

.icon-stroke--sm {
  stroke-width: 2px;
}

.icon-stroke--md {
  stroke-width: 3px;
}

.icon-stroke--lg {
  stroke-width: 4px;
}

.review-icon {
  fill: transparent;
  transition: fill $transition-duration $cubic-bezier;

  &.filled,
  &:hover {
    fill: $yellow;
  }

  &.empty {
    fill: $gray-2;
  }
}
