header {
  width: 100%;
  @include xs {
    height: $header-height-lt-sm;
  }
  @include gt-xs {
    height: $header-height-gt-xs;
  }

  .dropdown-target button {
    background-color: $white;
  }

  .setup-headshot {
    margin-top: $header-height-lt-sm - 36px;
  }

  .setup-headshot {
    margin-top: $header-height-gt-xs - 36px;
  }
}

nav.sticky-nav {
  > .content {
    @include gt-xs {
      @media (min-height: 300px) {
        position: sticky;
        top: $default-spacing * 4;
      }
    }

    #nav-link-container {
      position: relative;
      $nav-link-container-height: 32px;
      $nav-link-height: 16px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $nav-link-height;
        width: 4px;
        background-color: $pacific;
        transition: transform $transition-duration $cubic-bezier;
      }

      @for $i from 0 through 10 {
        &.active-#{$i}::after {
          transform: translateY(($nav-link-container-height - $nav-link-height) * .5 + $nav-link-container-height * $i);
        }
      }
    }
  }
}
