.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: $default-spacing * .25;
  width: 320px;
  box-sizing: none;
  color: $white;
  text-align: center;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  margin-left: -160px;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: $default-spacing;
  @include xs {
    left: $default-spacing;
    right: $default-spacing;
    width: calc(100% - #{$default-spacing * 2});
    max-width: calc(100% - #{$default-spacing * 2});
  }
  @include xs-sm {
    left: 15%;
    right: 15%;
    width: 70%;
    max-width: 70%;
  }
  @include gt-sm {
    left: 25%;
    right: 25%;
    width: 50%;
    max-width: 50%;
  }
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    margin: 0;
  }

  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-right {
    top: 0;
  }

  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  position: relative;
  min-height: $default-spacing * 4;
  margin-bottom: $default-spacing;
  padding: $default-spacing * .5;
  border-radius: $border-radius-lg;
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  cursor: pointer;
  direction: ltr;
  font-family: 'Soliel', sans-serif;
  font-weight: bold;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--default {
  background: $white;
  color: $gray-1;
}

.Toastify__toast--info {
  background: $light-blue;
}

.Toastify__toast--success {
  color: $medium-green;
  background-color: $light-green;
  border: 1px solid $medium-green;
}

.Toastify__toast--warning {
  background: $light-yellow;
}

.Toastify__toast--error {
  color: $red;
  background-color: $light-pink;
  border: 1px solid $red;
}

.Toastify__toast-body {
  font-family: Soleil, sans-serif;
  margin: auto 0;
  flex: 1;
}

.Toastify__close-button {
  color: $white;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: $opaque;
  transition: $transition-duration $cubic-bezier;
  align-self: flex-start;
}

.Toastify__close-button--default {
  color: $black;
}

.Toastify__close-button {
  &:focus,
  &:hover {
    opacity: 1;
  }
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: $opaque;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform $transition-duration;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}
@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--bottom-left,
.Toastify__slide-enter--top-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--bottom-right,
.Toastify__slide-enter--top-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--bottom-left,
.Toastify__slide-exit--top-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--bottom-right,
.Toastify__slide-exit--top-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}
/*# sourceMappingURL=ReactToastify.css.map */
