// react-transition-group sets the -enter, -exit, -enter-active, and -exit-active classes
// *-active classes represent which styles you want to animate to

// Transition items fade in/out
.item--fade-enter {
  opacity: 0;
}
.item--fade-enter-active {
  opacity: 1;
  transition: opacity $reveal-duration $cubic-bezier;
}
.item--fade-exit {
  opacity: 1;
}
.item--fade-exit-active {
  opacity: 0;
  transition: opacity $reveal-duration $cubic-bezier;
}

// Transition items fade in/out faster  (2x speed)
.item--fade-fast-enter {
  opacity: 0;
}
.item--fade-fast-enter-active {
  opacity: 1;
  transition: opacity $reveal-duration / 2 $cubic-bezier;
}
.item--fade-fast-exit {
  opacity: 1;
}
.item--fade-fast-exit-active {
  opacity: 0;
  transition: opacity $reveal-duration / 2 $cubic-bezier;
}

// Transition items slide in/out
.item--slide-enter {
  max-height: 0;
}
.item--slide-enter-active {
  max-height: 500px;
  transition: max-height $reveal-duration $cubic-bezier;
}
.item--slide-exit {
  max-height: 500px;
}
.item--slide-exit-active {
  max-height: 0;
  transition: max-height $reveal-duration $cubic-bezier;
}
.item--slide-enter-done.overflow-hidden {
  overflow: visible !important;
}

// Transition items slide in/out
.item--slide-short-enter {
  max-height: 0;
  overflow: hidden;
}
.item--slide-short-enter-active {
  max-height: 24px;
  transition: max-height $reveal-duration $cubic-bezier;
}
.item--slide-short-exit {
  max-height: 24px;
  overflow: hidden;
}
.item--slide-short-exit-active {
  max-height: 0;
  transition: max-height $reveal-duration $cubic-bezier;
}
.item--slide-short-enter-done.overflow-hidden {
  overflow: visible !important;
}

// Slide
.slide-open {
  overflow: hidden;
  transition: max-height $transition-duration $cubic-bezier;
}

.fade-slide-up {
  opacity: 0;
  animation: fadeSlideUp $animation-duration $animation-delay * 3 1 $linear forwards;
}

.fade-flip-up {
  transform: rotateX(-15deg);
  transform-style: preserve-3d;
  transform-origin: center top 10px;
}

.is-visible .fade-flip-up, .is-visible.fade-flip-up {
  opacity: 1;
  transform: rotateX(0);
}

// Check animation
.success-icon-animation {
  stroke-dasharray: 20;
  stroke-dashoffset: -20;
  animation: draw $animation-duration $transition-delay $cubic-bezier 1 forwards;
}

// Check animation
.checkmark-icon-animation {
  stroke-dasharray: 80;
  stroke-dashoffset: -80;
  animation: draw $animation-duration $transition-delay $cubic-bezier 1 forwards;
}

// Text slide up
.typing span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(20, end) infinite, textSlide 6s $cubic-bezier infinite;
}
