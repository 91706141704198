.inline-flex {
  display: inline-flex;
  align-items: center;
}

.block {
  display: block;
}

.block-sm {
  @include sm {
    display: block !important;
  }
}

.block-gt-sm {
  @include gt-sm {
    display: block !important;
  }
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.hidden {
  visibility: hidden;
}

.hide {
  display: none !important;
}

.hide-xs {
  @include xs {
    display: none !important;
  }
}

.hide-gt-xs {
  @include gt-xs {
    display: none !important;
  }
}

.hide-sm {
  @include sm {
    display: none !important;
  }
}

.hide-xs-sm {
  @include xs-sm {
    display: none !important;
  }
}

.hide-gt-sm {
  @include gt-sm {
    display: none !important;
  }
}

.hide-gt-md {
  @include gt-md {
    display: none !important;
  }
}

.hide-lt-lg {
  @include md {
    display: none !important;
  }
}

.squiggle--bottom:after {
  content: '';
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34.58 4.11'%3E%3Cpath d='M.72,3.42,2.1,2A2.39,2.39,0,0,1,6,2,2.39,2.39,0,0,0,9.81,2a2.39,2.39,0,0,1,3.86,0,2.4,2.4,0,0,0,3.86,0,2.38,2.38,0,0,1,3.85,0,2.4,2.4,0,0,0,3.86,0A2.39,2.39,0,0,1,29.1,2,2.4,2.4,0,0,0,33,2l.88-1' fill='none' stroke='currentColor' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: $default-spacing * 2;
  height: 4px;
}

.ta-center {
  text-align: center;
}

.ta-center--lt-sm {
  @include xs {
    text-align: center;
  }
}

.ta-center--sm {
  @include sm {
    text-align: center;
  }
}

.ta-right {
  text-align: right;
}

.ta-right--gt-sm {
  @include gt-sm {
    text-align: right;
  }
}

.ta-right--gt-xs {
  @include gt-xs {
    text-align: right;
  }
}

.ta-left {
  text-align: left;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-scroll-xs {
  @include xs {
    overflow-x: scroll;
  }
}

.bg--cover {
  background-size: cover;
  background-repeat: no-repeat;
}

.blur {
  filter: blur($default-blur);
}
