.squiggle-background-image {
  background-image: url('https://static-images.kopa.co/background/squiggles-background-3x.png');
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.signup-button {
  .select-role-button {
    height: 64px;
    width: 54px;
    background-repeat: no-repeat;
    background-position-y: initial;
    background-position-x: center;
    background-size: cover;
  }

  .renter-role-button-image {
    background-image: url('https://static-images.kopa.co/general/renter-signup-blue-2x.png');
  }
  &:hover, &:active {
    .renter-role-button-image {
      background-image: url('https://static-images.kopa.co/general/renter-signup-white-2x.png');
    }
  }

  .host-role-button-image {
    background-image: url('https://static-images.kopa.co/general/host-signup-blue-2x.png');
  }
  &:hover, &:active {
    .host-role-button-image {
      background-image: url('https://static-images.kopa.co/general/host-signup-white-2x.png');
    }
  }
}
