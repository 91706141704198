.active-bar {
  background-color: $pacific;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
}

.selected-tab {
  position: relative;

  &::after {
    @extend .active-bar;
  }
}
