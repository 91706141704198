.listing-row-loader {
  .animated-background {
    position: relative;
    width: 100%;
    height: 250px;
    background: $gray-3;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    animation: placeholderShimmer 1.25s linear infinite forwards;
    @include gt-sm {
      height: 190px;
    }
  }

  .bg--masker {
    position: absolute;
    background: $white;
    height: $default-spacing;
  }

  /* Every thing below this is just positioning */
  .bg--masker.left-column {
    left: 50%;
    width: $default-spacing;
    height: 100%;
  }
  .bg--masker.header-top,
  .bg--masker.header-bottom,
  .bg--masker.subheader-bottom {
    top: 0;
    left: 50%;
    right: 0;
  }

  .bg--masker.header-left,
  .bg--masker.subheader-left,
  .bg--masker.header-right,
  .bg--masker.subheader-right {
    top: $default-spacing;
    left: 50%;
    height: $default-spacing;
    width: $default-spacing;
  }

  .bg--masker.header-bottom {
    top: $default-spacing * 2;
  }

  .bg--masker.subheader-left,
  .bg--masker.subheader-right {
    top: $default-spacing * 3;
  }

  .bg--masker.header-right,
  .bg--masker.subheader-right {
    left: 84%;
    right: 0;
    width: auto;
  }

  .bg--masker.subheader-bottom {
    top: $default-spacing * 4;
  }

  .bg--masker.subheader-right {
    top: $default-spacing * 5;
    left: 75%;
    right: 0;
    width: auto;
  }

  .bg--masker.content {
    top: $default-spacing * 6;
    left: 50%;
    right: 0;
    height: 155px;

    @include gt-sm {
      height: 95px;
    }
  }
}
