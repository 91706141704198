a {
  color: $pacific;
  cursor: pointer;
  text-decoration: none;

  &.disabled {
    opacity: $transparent;
    pointer-events: none;
    cursor: default;
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(.hover-none):not(.active):not(.button):hover {
    text-decoration: underline;
  }

  &.active {
    color: $black;
  }

  &.chevron-link {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: -$default-spacing;
      bottom: 0;
      width: 12px;
      height: 90%;
      background: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23185C97' stroke-width='2' transform='translate(7 8) rotate(-90) translate(-6.5 -7.5)'%3E%3Cpolyline points='0.5 9.0226 6.4887 15 12.5 9' /%3E%3Cline x1='6.5' x2='6.5' y1='14' y2='.5' /%3E%3C/g%3E%3C/svg%3E") 100% no-repeat;
      transition: right $transition-duration $cubic-bezier;
    }

    &:hover::before {
      right: -$default-spacing * 1.25;
    }
  }

  &.input-link {
    position: absolute;
    top: $default-spacing * -1.25;
    right: 0;

    &.offset-right {
      right: calc(100% - #{100px + 150px + $default-spacing * .5});

      @include gt-sm {
        right: 0;
      }
    }
  }

  &.underline:not(.white):hover {
    color: $black;
  }

  &.black.hover-none:hover {
    color: $gray-0;
  }

  &.white:not(.underline):not(.hover-none):hover {
    box-shadow: inset 0 -1px 0 0 currentColor;
  }

  &.opacity-hover {
    transition: opacity $transition-duration $cubic-bezier;

    &:hover {
      opacity: $opaque;
    }
  }

  &.lighten-hover:hover {
    color: $hue-1;
  }
}
