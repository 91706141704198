$bottom-listing-list-bar-height: 56px;
.listing-search-bar {
  @include gt-sm {
    top: $header-height-gt-xs;
  }
}

.listings-wrapper {
  .listings-container {
    @include sm {
      position: absolute;
      top: calc(#{$header-height-gt-xs + $search-filters-height-gt-xs});
      width: 100%;
      max-height: 100%;
      transition: top $transition-duration $ease-in-out, max-height $transition-duration $ease-in-out;

      &.minimized {
        top: calc((var(--mobile-vh, 1vh) * 100) - #{$bottom-listing-list-bar-height});
        max-height: $bottom-listing-list-bar-height;
        overflow: hidden;
      }
    }
    @include xs {
      top: calc(#{$header-height-lt-sm + $search-filters-height-lt-sm});
    }
    @include gt-sm {
      width: 60%;
    }
  }

  .listings-map {
    .content {
      @include xs {
        height: calc((var(--mobile-vh, 1vh) * 100) - #{$header-height-lt-sm + $search-filters-height-lt-sm + $bottom-listing-list-bar-height});
      }
      @include xs-sm {
        height: calc((var(--mobile-vh, 1vh) * 100) - #{$header-height-gt-xs + $search-filters-height-gt-xs + $bottom-listing-list-bar-height});
      }
    }
    @include gt-sm {
      width: 40%;

      .content {
        top: #{$header-height-gt-xs + $search-filters-height-gt-xs};
        height: calc(100vh - #{$header-height-gt-xs + $search-filters-height-gt-xs});
      }
    }
  }
}

.housing-search-wrapper {
  .housing-search-container {
    width: 60%;

    @include sm {
      width: 100%;
      margin-top: 0;
    }
  }

  .housing-search-map {
    width: 40%;

    .content {
      top: $header-height-gt-xs;
      width: 100%;
      margin: 0;
      @include sm {
        height: calc((var(--mobile-vh, 1vh) * 100) - #{$search-filters-height-lt-sm});
      }
      @include gt-sm {
        top: $header-height-gt-xs;
        height: calc(100vh - #{$header-height-gt-xs});
      }
    }
  }
}

.saved-search-dropdown-contents {
  .saved-search-dropdown-contents-row {
    &:hover:not(.disabled) {
      background: $gray-3;
    }

    button:disabled {
      color: $hue-2;
    }
  }
}

@include sm {
  .filters-dot::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 8px;
    width: 4px;
    height: 4px;
    background-color: $white;
    border-radius: 50%;
  }
}
