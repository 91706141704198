// Padding
.p-top--none {
  padding-top: 0 !important;
}

.p-right--none {
  padding-right: 0 !important;
}

.p-bottom--none {
  padding-bottom: 0 !important;
}

.p-left--none {
  padding-left: 0 !important;
}

.p-vertical--none {
  padding-top: 0;
  padding-bottom: 0;
}

.p-horizontal--none {
  padding-right: 0;
  padding-left: 0;
}

.p-around--none {
  padding: 0;
}

.p-top--xxx-small {
  padding-top: $default-spacing * .125;
}

.p-right--xxx-small {
  padding-right: $default-spacing * .125;
}

.p-bottom--xxx-small {
  padding-bottom: $default-spacing * .125;
}

.p-left--xxx-small {
  padding-left: $default-spacing * .125;
}

.p-vertical--xxx-small {
  padding-top: $default-spacing * .125;
  padding-bottom: $default-spacing * .125;
}

.p-horizontal--xxx-small {
  padding-right: $default-spacing * .125;
  padding-left: $default-spacing * .125;
}

.p-around--xxx-small {
  padding: $default-spacing * .125;
}

.p-top--xx-small {
  padding-top: $default-spacing * .25;
}

.p-right--xx-small {
  padding-right: $default-spacing * .25;
}

.p-bottom--xx-small {
  padding-bottom: $default-spacing * .25;
}

.p-left--xx-small {
  padding-left: $default-spacing * .25;
}

.p-vertical--xx-small {
  padding-top: $default-spacing * .25;
  padding-bottom: $default-spacing * .25;
}

.p-horizontal--xx-small {
  padding-right: $default-spacing * .25;
  padding-left: $default-spacing * .25;
}

.p-around--xx-small {
  padding: $default-spacing * .25;
}

.p-top--x-small {
  padding-top: $default-spacing * .5;
}

.p-right--x-small {
  padding-right: $default-spacing * .5;
}

.p-bottom--x-small {
  padding-bottom: $default-spacing * .5;
}

.p-left--x-small {
  padding-left: $default-spacing * .5;
}

.p-vertical--x-small {
  padding-top: $default-spacing * .5;
  padding-bottom: $default-spacing * .5;
}

.p-horizontal--x-small {
  padding-right: $default-spacing * .5;
  padding-left: $default-spacing * .5;
}

.p-around--x-small {
  padding: $default-spacing * .5;
}

.p-top--small {
  padding-top: $default-spacing * .75;
}

.p-right--small {
  padding-right: $default-spacing * .75;
}

.p-bottom--small {
  padding-bottom: $default-spacing * .75;
}

.p-left--small {
  padding-left: $default-spacing * .75;
}

.p-vertical--small {
  padding-top: $default-spacing * .75;
  padding-bottom: $default-spacing * .75;
}

.p-horizontal--small {
  padding-right: $default-spacing * .75;
  padding-left: $default-spacing * .75;
}

.p-around--small {
  padding: $default-spacing * .75;
}

.p-top--medium {
  padding-top: $default-spacing;
}

.p-right--medium {
  padding-right: $default-spacing;
}

.p-bottom--medium {
  padding-bottom: $default-spacing;
}

.p-left--medium {
  padding-left: $default-spacing;
}

.p-vertical--medium {
  padding-top: $default-spacing;
  padding-bottom: $default-spacing;
}

.p-horizontal--medium {
  padding-right: $default-spacing;
  padding-left: $default-spacing;
}

.p-around--medium {
  padding: $default-spacing;
}

.p-top--large {
  padding-top: $default-spacing * 1.5;
}

.p-right--large {
  padding-right: $default-spacing * 1.5;
}

.p-bottom--large {
  padding-bottom: $default-spacing * 1.5;
}

.p-left--large {
  padding-left: $default-spacing * 1.5;
}

.p-vertical--large {
  padding-top: $default-spacing * 1.5;
  padding-bottom: $default-spacing * 1.5;
}

.p-horizontal--large {
  padding-right: $default-spacing * 1.5;
  padding-left: $default-spacing * 1.5;
}

.p-around--large {
  padding: $default-spacing * 1.5;
}

.p-top--x-large {
  padding-top: $default-spacing * 2;
}

.p-right--x-large {
  padding-right: $default-spacing * 2;
}

.p-bottom--x-large {
  padding-bottom: $default-spacing * 2;
}

.p-left--x-large {
  padding-left: $default-spacing * 2;
}

.p-vertical--x-large {
  padding-top: $default-spacing * 2;
  padding-bottom: $default-spacing * 2;
}

.p-horizontal--x-large {
  padding-right: $default-spacing * 2;
  padding-left: $default-spacing * 2;
}

.p-around--x-large {
  padding: $default-spacing * 2;
}

.p-top--xx-large {
  padding-top: $default-spacing * 3;
}

.p-right--xx-large {
  padding-right: $default-spacing * 3;
}

.p-bottom--xx-large {
  padding-bottom: $default-spacing * 3;
}

.p-left--xx-large {
  padding-left: $default-spacing * 3;
}

.p-vertical--xx-large {
  padding-top: $default-spacing * 3;
  padding-bottom: $default-spacing * 3;
}

.p-horizontal--xx-large {
  padding-right: $default-spacing * 3;
  padding-left: $default-spacing * 3;
}

.p-around--xx-large {
  padding: $default-spacing * 3;
}

.p-top--xxx-large {
  padding-top: $default-spacing * 4;
}

.p-right--xxx-large {
  padding-right: $default-spacing * 4;
}

.p-bottom--xxx-large {
  padding-bottom: $default-spacing * 4;
}

.p-left--xxx-large {
  padding-left: $default-spacing * 4;
}

.p-vertical--xxx-large {
  padding-top: $default-spacing * 4;
  padding-bottom: $default-spacing * 4;
}

.p-horizontal--xxx-large {
  padding-right: $default-spacing * 4;
  padding-left: $default-spacing * 4;
}

.p-top--xxxx-large {
  @include xs {
    padding-top: $default-spacing * 4;
  }
  @include gt-xs {
    padding-top: $default-spacing * 6;
  }
}

.p-bottom--xxxx-large {
  padding-bottom: $default-spacing * 6;
}

.p-vertical--xxxx-large {
  @include xs {
    padding-top: $default-spacing * 6;
    padding-bottom: $default-spacing * 6;
  }
  @include gt-xs {
    padding-top: $default-spacing * 8;
    padding-bottom: $default-spacing * 8;
  }
}

.p-table--medium-2w {
  @include gt-xs {
    &:nth-child(odd) {
      padding-right: $default-spacing;
    }

    &:nth-child(even) {
      padding-left: $default-spacing;
    }
  }
}

.p-table--x-large-2w {
  @include sm-md {
    &:nth-child(odd) {
      padding-right: $default-spacing;
    }

    &:nth-child(even) {
      padding-left: $default-spacing;
    }
  }
  @include gt-md {
    &:nth-child(odd) {
      padding-right: $default-spacing * 2;
    }

    &:nth-child(even) {
      padding-left: $default-spacing * 2;
    }
  }
}

.p-top--table-cell-spacing {
  padding-top: $default-spacing * .5;
}

.p-right--table-cell-spacing {
  padding-right: $default-spacing * .5;
}

.p-bottom--table-cell-spacing {
  padding-bottom: $default-spacing * .5;
}

.p-left--table-cell-spacing {
  padding-left: $default-spacing * .5;
}

.p-vertical--table-cell-spacing {
  padding-top: $default-spacing * .5;
  padding-bottom: $default-spacing * .5;
}

.p-horizontal--table-cell-spacing {
  padding-right: $default-spacing * .5;
  padding-left: $default-spacing * .5;
}

.p-around--table-cell-spacing {
  padding: $default-spacing * .5;
}

// Margin
.m-horizontal--auto {
  margin-left: auto;
  margin-right: auto;
}

.m-top--none {
  margin-top: 0 !important;
}

.m-right--none {
  margin-right: 0 !important;
}

.m-bottom--none {
  margin-bottom: 0 !important;
}

.m-left--none {
  margin-left: 0 !important;
}

.m-vertical--none {
  margin-top: 0;
  margin-bottom: 0;
}

.m-horizontal--none {
  margin-right: 0;
  margin-left: 0;
}

.m-top--xxx-small {
  margin-top: $default-spacing * .125;
}

.m-right--xxx-small {
  margin-right: $default-spacing * .125;
}

.m-bottom--xxx-small {
  margin-bottom: $default-spacing * .125;
}

.m-left--xxx-small {
  margin-left: $default-spacing * .125;
}

.m-vertical--xxx-small {
  margin-top: $default-spacing * .125;
  margin-bottom: $default-spacing * .125;
}

.m-horizontal--xxx-small {
  margin-right: $default-spacing * .125;
  margin-left: $default-spacing * .125;
}

.m-around--xxx-small {
  margin: $default-spacing * .125;
}

.m-top--xx-small {
  margin-top: $default-spacing * .25;
}

.m-right--xx-small {
  margin-right: $default-spacing * .25;
}

.m-bottom--xx-small {
  margin-bottom: $default-spacing * .25;
}

.m-left--xx-small {
  margin-left: $default-spacing * .25;
}

.m-vertical--xx-small {
  margin-top: $default-spacing * .25;
  margin-bottom: $default-spacing * .25;
}

.m-horizontal--xx-small {
  margin-right: $default-spacing * .25;
  margin-left: $default-spacing * .25;
}

.m-around--xx-small {
  margin: $default-spacing * .25;
}

.m-top--x-small {
  margin-top: $default-spacing * .5;
}

.m-right--x-small {
  margin-right: $default-spacing * .5;
}

.m-bottom--x-small {
  margin-bottom: $default-spacing * .5;
}

.m-left--x-small {
  margin-left: $default-spacing * .5;
}

.m-vertical--x-small {
  margin-top: $default-spacing * .5;
  margin-bottom: $default-spacing * .5;
}

.m-horizontal--x-small {
  margin-right: $default-spacing * .5;
  margin-left: $default-spacing * .5;
}

.m-around--x-small {
  margin: $default-spacing * .5;
}

.m-top--small {
  margin-top: $default-spacing * .75;
}

.m-right--small {
  margin-right: $default-spacing * .75;
}

.m-bottom--small {
  margin-bottom: $default-spacing * .75;
}

.m-left--small {
  margin-left: $default-spacing * .75;
}

.m-vertical--small {
  margin-top: $default-spacing * .75;
  margin-bottom: $default-spacing * .75;
}

.m-horizontal--small {
  margin-right: $default-spacing * .75;
  margin-left: $default-spacing * .75;
}

.m-around--small {
  margin: $default-spacing * .75;
}

.m-top--medium {
  margin-top: $default-spacing;
}

.m-right--medium {
  margin-right: $default-spacing;
}

.m-bottom--medium {
  margin-bottom: $default-spacing;
}

.m-bottom--medium-negative {
  margin-bottom: -$default-spacing;
}

.m-left--medium {
  margin-left: $default-spacing;
}

.m-vertical--medium {
  margin-top: $default-spacing;
  margin-bottom: $default-spacing;
}

.m-horizontal--medium {
  margin-right: $default-spacing;
  margin-left: $default-spacing;
}

.m-around--medium {
  margin: $default-spacing;
}

.m-top--large {
  margin-top: $default-spacing * 1.5;
}

.m-right--large {
  margin-right: $default-spacing * 1.5;
}

.m-bottom--large {
  margin-bottom: $default-spacing * 1.5;
}

.m-left--large {
  margin-left: $default-spacing * 1.5;
}

.m-vertical--large {
  margin-top: $default-spacing * 1.5;
  margin-bottom: $default-spacing * 1.5;
}

.m-horizontal--large {
  margin-right: $default-spacing * 1.5;
  margin-left: $default-spacing * 1.5;
}

.m-around--large {
  margin: $default-spacing * 1.5;
}

.m-top--x-large {
  margin-top: $default-spacing * 2;
}

.m-right--x-large {
  margin-right: $default-spacing * 2;
}

.m-bottom--x-large {
  margin-bottom: $default-spacing * 2;
}

.m-bottom--x-large-negative {
  margin-bottom: -$default-spacing * 2;
}

.m-left--x-large {
  margin-left: $default-spacing * 2;
}

.m-vertical--x-large {
  margin-top: $default-spacing * 2;
  margin-bottom: $default-spacing * 2;
}

.m-horizontal--x-large {
  margin-right: $default-spacing * 2;
  margin-left: $default-spacing * 2;
}

.m-around--x-large {
  margin: $default-spacing * 2;
}

.m-top--xx-large {
  margin-top: $default-spacing * 3;
}

.m-right--xx-large {
  margin-right: $default-spacing * 3;
}

.m-bottom--xx-large {
  margin-bottom: $default-spacing * 3;
}

.m-left--xx-large {
  margin-left: $default-spacing * 3;
}

.m-vertical--xx-large {
  margin-top: $default-spacing * 3;
  margin-bottom: $default-spacing * 3;
}

.m-horizontal--xx-large {
  margin-right: $default-spacing * 3;
  margin-left: $default-spacing * 3;
}

.m-around--xx-large {
  margin: $default-spacing * 3;
}

.m-top--xxx-large {
  margin-top: $default-spacing * 4;
}

.m-right--xxx-large {
  margin-right: $default-spacing * 4;
}

.m-bottom--xxx-large {
  margin-bottom: $default-spacing * 4;
}

.m-bottom--xxxx-large {
  margin-bottom: $default-spacing * 6;
}

.m-left--xxx-large {
  margin-left: $default-spacing * 4;
}

.m-vertical--xxx-large {
  margin-top: $default-spacing * 4;
  margin-bottom: $default-spacing * 4;
}

.m-horizontal--xxx-large {
  margin-right: $default-spacing * 4;
  margin-left: $default-spacing * 4;
}

.m-around--xxx-large {
  margin: $default-spacing * 4;
}

.m-top--xxxx-large {
  @include xs {
    margin-top: $default-spacing * 4;
  }
  @include gt-xs {
    margin-top: $default-spacing * 6;
  }
}

@include xs {
  .p-top--xx-small-lt-sm {
    padding-top: $default-spacing * .25;
  }

  .p-left--x-small-lt-sm {
    padding-left: $default-spacing * .5;
  }

  .p-right--x-small-lt-sm {
    padding-right: $default-spacing * .5;
  }

  .p-around--large-lt-sm {
    padding: $default-spacing * 1.5;
  }

  .p-horizontal--large-lt-sm {
    padding-right: $default-spacing * 1.5;
    padding-left: $default-spacing * 1.5;
  }

  .p-top--x-large-lt-sm {
    padding-top: $default-spacing * 2;
  }

  .p-vertical--x-large-lt-sm {
    padding-top: $default-spacing * 2;
    padding-bottom: $default-spacing * 2;
  }

  .p-vertical--xx-large-lt-sm {
    padding-top: $default-spacing * 3;
    padding-bottom: $default-spacing * 3;
  }

  .p-horizontal--xx-large-lt-sm {
    padding-right: $default-spacing * 2.5;
    padding-left: $default-spacing * 2.5;
  }

  .p-top--xxx-large-lt-sm {
    padding-top: $default-spacing * 4;
  }

  .p-bottom--xxx-large-lt-sm {
    padding-bottom: $default-spacing * 4;
  }

  .p-vertical--xxxx-large-lt-sm {
    padding-top: $default-spacing * 8;
    padding-bottom: $default-spacing * 8;
  }

  .m-left--small-lt-sm {
    margin-left: $default-spacing * 0.75;
  }

  .m-left--large-lt-sm {
    margin-left: $default-spacing * 1.5;
  }

  .m-right--medium-lt-sm {
    margin-right: $default-spacing;
  }

  .m-top--xx-small-lt-sm {
    margin-top: $default-spacing * .25;
  }

  .m-top--x-small-lt-sm {
    margin-top: $default-spacing * .5;
  }

  .m-top--small-lt-sm {
    margin-top: $default-spacing * .75;
  }

  .m-bottom--medium-lt-sm {
    margin-bottom: $default-spacing;
  }

  .m-top--medium-lt-sm {
    margin-top: $default-spacing;
  }

  .m-top--large-lt-sm {
    margin-top: $default-spacing * 1.5;
  }

  .m-top--x-large-lt-sm {
    margin-top: $default-spacing * 2;
  }

  .m-bottom--x-large-lt-sm {
    margin-bottom: $default-spacing * 2;
  }

  .m-vertical--x-large-lt-sm {
    margin-top: $default-spacing * 2;
    margin-bottom: $default-spacing * 2;
  }

  .m-top--xx-large-lt-sm {
    margin-top: $default-spacing * 3;
  }

  .m-vertical--xx-large-lt-sm {
    margin-top: $default-spacing * 3;
    margin-bottom: $default-spacing * 3;
  }

  .m-horizontal--auto-lt-sm {
    margin-left: auto;
    margin-right: auto;
  }
}

@include gt-xs {
  .p-top--none-gt-xs {
    padding-top: 0 !important;
  }

  .p-right--none-gt-xs {
    padding-right: 0 !important;
  }

  .p-bottom--none-gt-xs {
    padding-bottom: 0 !important;
  }

  .p-left--none-gt-xs {
    padding-left: 0 !important;
  }

  .p-vertical--none-gt-xs {
    padding-top: 0;
    padding-bottom: 0;
  }

  .p-horizontal--none-gt-xs {
    padding-right: 0;
    padding-left: 0;
  }

  .p-around--none-gt-xs {
    padding: 0;
  }

  .p-top--xxx-small-gt-xs {
    padding-top: $default-spacing * .125;
  }

  .p-right--xxx-small-gt-xs {
    padding-right: $default-spacing * .125;
  }

  .p-bottom--xxx-small-gt-xs {
    padding-bottom: $default-spacing * .125;
  }

  .p-left--xxx-small-gt-xs {
    padding-left: $default-spacing * .125;
  }

  .p-vertical--xxx-small-gt-xs {
    padding-top: $default-spacing * .125;
    padding-bottom: $default-spacing * .125;
  }

  .p-horizontal--xxx-small-gt-xs {
    padding-right: $default-spacing * .125;
    padding-left: $default-spacing * .125;
  }

  .p-around--xxx-small-gt-xs {
    padding: $default-spacing * .125;
  }

  .p-top--xx-small-gt-xs {
    padding-top: $default-spacing * .25;
  }

  .p-right--xx-small-gt-xs {
    padding-right: $default-spacing * .25;
  }

  .p-bottom--xx-small-gt-xs {
    padding-bottom: $default-spacing * .25;
  }

  .p-left--xx-small-gt-xs {
    padding-left: $default-spacing * .25;
  }

  .p-vertical--xx-small-gt-xs {
    padding-top: $default-spacing * .25;
    padding-bottom: $default-spacing * .25;
  }

  .p-horizontal--xx-small-gt-xs {
    padding-right: $default-spacing * .25;
    padding-left: $default-spacing * .25;
  }

  .p-around--xx-small-gt-xs {
    padding: $default-spacing * .25;
  }

  .p-top--x-small-gt-xs {
    padding-top: $default-spacing * .5;
  }

  .p-right--x-small-gt-xs {
    padding-right: $default-spacing * .5;
  }

  .p-bottom--x-small-gt-xs {
    padding-bottom: $default-spacing * .5;
  }

  .p-left--x-small-gt-xs {
    padding-left: $default-spacing * .5;
  }

  .p-vertical--x-small-gt-xs {
    padding-top: $default-spacing * .5;
    padding-bottom: $default-spacing * .5;
  }

  .p-horizontal--x-small-gt-xs {
    padding-right: $default-spacing * .5;
    padding-left: $default-spacing * .5;
  }

  .p-around--x-small-gt-xs {
    padding: $default-spacing * .5;
  }

  .p-top--small-gt-xs {
    padding-top: $default-spacing * .75;
  }

  .p-right--small-gt-xs {
    padding-right: $default-spacing * .75;
  }

  .p-bottom--small-gt-xs {
    padding-bottom: $default-spacing * .75;
  }

  .p-left--small-gt-xs {
    padding-left: $default-spacing * .75;
  }

  .p-vertical--small-gt-xs {
    padding-top: $default-spacing * .75;
    padding-bottom: $default-spacing * .75;
  }

  .p-horizontal--small-gt-xs {
    padding-right: $default-spacing * .75;
    padding-left: $default-spacing * .75;
  }

  .p-around--small-gt-xs {
    padding: $default-spacing * .75;
  }

  .p-top--medium-gt-xs {
    padding-top: $default-spacing;
  }

  .p-right--medium-gt-xs {
    padding-right: $default-spacing;
  }

  .p-bottom--medium-gt-xs {
    padding-bottom: $default-spacing;
  }

  .p-left--medium-gt-xs {
    padding-left: $default-spacing;
  }

  .p-vertical--medium-gt-xs {
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;
  }

  .p-horizontal--medium-gt-xs {
    padding-right: $default-spacing;
    padding-left: $default-spacing;
  }

  .p-around--medium-gt-xs {
    padding: $default-spacing;
  }

  .p-top--large-gt-xs {
    padding-top: $default-spacing * 1.5;
  }

  .p-right--large-gt-xs {
    padding-right: $default-spacing * 1.5;
  }

  .p-bottom--large-gt-xs {
    padding-bottom: $default-spacing * 1.5;
  }

  .p-left--large-gt-xs {
    padding-left: $default-spacing * 1.5;
  }

  .p-vertical--large-gt-xs {
    padding-top: $default-spacing * 1.5;
    padding-bottom: $default-spacing * 1.5;
  }

  .p-horizontal--large-gt-xs {
    padding-right: $default-spacing * 1.5;
    padding-left: $default-spacing * 1.5;
  }

  .p-around--large-gt-xs {
    padding: $default-spacing * 1.5;
  }

  .p-top--x-large-gt-xs {
    padding-top: $default-spacing * 2;
  }

  .p-right--x-large-gt-xs {
    padding-right: $default-spacing * 2;
  }

  .p-bottom--x-large-gt-xs {
    padding-bottom: $default-spacing * 2;
  }

  .p-left--x-large-gt-xs {
    padding-left: $default-spacing * 2;
  }

  .p-vertical--x-large-gt-xs {
    padding-top: $default-spacing * 2;
    padding-bottom: $default-spacing * 2;
  }

  .p-horizontal--x-large-gt-xs {
    padding-right: $default-spacing * 2;
    padding-left: $default-spacing * 2;
  }

  .p-around--x-large-gt-xs {
    padding: $default-spacing * 2;
  }

  .p-top--xx-large-gt-xs {
    padding-top: $default-spacing * 3;
  }

  .p-right--xx-large-gt-xs {
    padding-right: $default-spacing * 3;
  }

  .p-bottom--xx-large-gt-xs {
    padding-bottom: $default-spacing * 3;
  }

  .p-left--xx-large-gt-xs {
    padding-left: $default-spacing * 3;
  }

  .p-vertical--xx-large-gt-xs {
    padding-top: $default-spacing * 3;
    padding-bottom: $default-spacing * 3;
  }

  .p-horizontal--xx-large-gt-xs {
    padding-right: $default-spacing * 3;
    padding-left: $default-spacing * 3;
  }

  .p-around--xx-large-gt-xs {
    padding: $default-spacing * 3;
  }

  .p-top--xxx-large-gt-xs {
    padding-top: $default-spacing * 4;
  }

  .p-right--xxx-large-gt-xs {
    padding-right: $default-spacing * 4;
  }

  .p-bottom--xxx-large-gt-xs {
    padding-bottom: $default-spacing * 4;
  }

  .p-left--xxx-large-gt-xs {
    padding-left: $default-spacing * 4;
  }

  .p-vertical--xxx-large-gt-xs {
    padding-top: $default-spacing * 4;
    padding-bottom: $default-spacing * 4;
  }

  .p-horizontal--xxx-large-gt-xs {
    padding-right: $default-spacing * 4;
    padding-left: $default-spacing * 4;
  }

  .p-horizontal--xxxx-large-gt-xs {
    padding-right: $default-spacing * 6;
    padding-left: $default-spacing * 6;
  }

  .p-top--xxxx-large-gt-xs {
    padding-top: $default-spacing * 6;
  }

  .p-around--xxx-large-gt-xs {
    padding: $default-spacing * 4;
  }

  .p-vertical--xxxx-large-gt-xs {
    padding-top: $default-spacing * 6;
    padding-bottom: $default-spacing * 6;
  }

  .p-vertical--xxxxx-large-gt-xs {
    padding-top: $default-spacing * 10;
    padding-bottom: $default-spacing * 10;
  }

  .m-horizontal--auto-gt-xs {
    margin-left: auto;
    margin-right: auto;
  }

  .m-top--none-gt-xs {
    margin-top: 0 !important;
  }

  .m-right--none-gt-xs {
    margin-right: 0 !important;
  }

  .m-bottom--none-gt-xs {
    margin-bottom: 0 !important;
  }

  .m-left--none-gt-xs {
    margin-left: 0 !important;
  }

  .m-vertical--none-gt-xs {
    margin-top: 0;
    margin-bottom: 0;
  }

  .m-horizontal--none-gt-xs {
    margin-right: 0;
    margin-left: 0;
  }

  .m-top--xxx-small-gt-xs {
    margin-top: $default-spacing * .125;
  }

  .m-right--xxx-small-gt-xs {
    margin-right: $default-spacing * .125;
  }

  .m-bottom--xxx-small-gt-xs {
    margin-bottom: $default-spacing * .125;
  }

  .m-left--xxx-small-gt-xs {
    margin-left: $default-spacing * .125;
  }

  .m-vertical--xxx-small-gt-xs {
    margin-top: $default-spacing * .125;
    margin-bottom: $default-spacing * .125;
  }

  .m-horizontal--xxx-small-gt-xs {
    margin-right: $default-spacing * .125;
    margin-left: $default-spacing * .125;
  }

  .m-around--xxx-small-gt-xs {
    margin: $default-spacing * .125;
  }

  .m-top--xx-small-gt-xs {
    margin-top: $default-spacing * .25;
  }

  .m-right--xx-small-gt-xs {
    margin-right: $default-spacing * .25;
  }

  .m-bottom--xx-small-gt-xs {
    margin-bottom: $default-spacing * .25;
  }

  .m-left--xx-small-gt-xs {
    margin-left: $default-spacing * .25;
  }

  .m-vertical--xx-small-gt-xs {
    margin-top: $default-spacing * .25;
    margin-bottom: $default-spacing * .25;
  }

  .m-horizontal--xx-small-gt-xs {
    margin-right: $default-spacing * .25;
    margin-left: $default-spacing * .25;
  }

  .m-around--xx-small-gt-xs {
    margin: $default-spacing * .25;
  }

  .m-top--x-small-gt-xs {
    margin-top: $default-spacing * .5;
  }

  .m-right--x-small-gt-xs {
    margin-right: $default-spacing * .5;
  }

  .m-bottom--x-small-gt-xs {
    margin-bottom: $default-spacing * .5;
  }

  .m-left--x-small-gt-xs {
    margin-left: $default-spacing * .5;
  }

  .m-vertical--x-small-gt-xs {
    margin-top: $default-spacing * .5;
    margin-bottom: $default-spacing * .5;
  }

  .m-horizontal--x-small-gt-xs {
    margin-right: $default-spacing * .5;
    margin-left: $default-spacing * .5;
  }

  .m-around--x-small-gt-xs {
    margin: $default-spacing * .5;
  }

  .m-top--small-gt-xs {
    margin-top: $default-spacing * .75;
  }

  .m-right--small-gt-xs {
    margin-right: $default-spacing * .75;
  }

  .m-bottom--small-gt-xs {
    margin-bottom: $default-spacing * .75;
  }

  .m-left--small-gt-xs {
    margin-left: $default-spacing * .75;
  }

  .m-vertical--small-gt-xs {
    margin-top: $default-spacing * .75;
    margin-bottom: $default-spacing * .75;
  }

  .m-horizontal--small-gt-xs {
    margin-right: $default-spacing * .75;
    margin-left: $default-spacing * .75;
  }

  .m-around--small-gt-xs {
    margin: $default-spacing * .75;
  }

  .m-top--medium-gt-xs {
    margin-top: $default-spacing;
  }

  .m-right--medium-gt-xs {
    margin-right: $default-spacing;
  }

  .m-bottom--medium-gt-xs {
    margin-bottom: $default-spacing;
  }

  .m-left--medium-gt-xs {
    margin-left: $default-spacing;
  }

  .m-vertical--medium-gt-xs {
    margin-top: $default-spacing;
    margin-bottom: $default-spacing;
  }

  .m-horizontal--medium-gt-xs {
    margin-right: $default-spacing;
    margin-left: $default-spacing;
  }

  .m-around--medium-gt-xs {
    margin: $default-spacing;
  }

  .m-top--large-gt-xs {
    margin-top: $default-spacing * 1.5;
  }

  .m-right--large-gt-xs {
    margin-right: $default-spacing * 1.5;
  }

  .m-bottom--large-gt-xs {
    margin-bottom: $default-spacing * 1.5;
  }

  .m-left--large-gt-xs {
    margin-left: $default-spacing * 1.5;
  }

  .m-vertical--large-gt-xs {
    margin-top: $default-spacing * 1.5;
    margin-bottom: $default-spacing * 1.5;
  }

  .m-horizontal--large-gt-xs {
    margin-right: $default-spacing * 1.5;
    margin-left: $default-spacing * 1.5;
  }

  .m-around--large-gt-xs {
    margin: $default-spacing * 1.5;
  }

  .m-top--x-large-gt-xs {
    margin-top: $default-spacing * 2;
  }

  .m-right--x-large-gt-xs {
    margin-right: $default-spacing * 2;
  }

  .m-bottom--x-large-gt-xs {
    margin-bottom: $default-spacing * 2;
  }

  .m-left--x-large-gt-xs {
    margin-left: $default-spacing * 2;
  }

  .m-vertical--x-large-gt-xs {
    margin-top: $default-spacing * 2;
    margin-bottom: $default-spacing * 2;
  }

  .m-horizontal--x-large-gt-xs {
    margin-right: $default-spacing * 2;
    margin-left: $default-spacing * 2;
  }

  .m-around--x-large-gt-xs {
    margin: $default-spacing * 2;
  }

  .m-top--xx-large-gt-xs {
    margin-top: $default-spacing * 3;
  }

  .m-right--xx-large-gt-xs {
    margin-right: $default-spacing * 3;
  }

  .m-bottom--xx-large-gt-xs {
    margin-bottom: $default-spacing * 3;
  }

  .m-left--xx-large-gt-xs {
    margin-left: $default-spacing * 3;
  }

  .m-vertical--xx-large-gt-xs {
    margin-top: $default-spacing * 3;
    margin-bottom: $default-spacing * 3;
  }

  .m-horizontal--xx-large-gt-xs {
    margin-right: $default-spacing * 3;
    margin-left: $default-spacing * 3;
  }

  .m-around--xx-large-gt-xs {
    margin: $default-spacing * 3;
  }

  .m-top--xxx-large-gt-xs {
    margin-top: $default-spacing * 4;
  }

  .m-top--xxxx-large-gt-xs {
    margin-top: $default-spacing * 6;
  }


  .m-right--xxx-large-gt-xs {
    margin-right: $default-spacing * 4;
  }

  .m-bottom--xxx-large-gt-xs {
    margin-bottom: $default-spacing * 4;
  }

  .m-left--xxx-large-gt-xs {
    margin-left: $default-spacing * 4;
  }

  .m-vertical--xxx-large-gt-xs {
    margin-top: $default-spacing * 4;
    margin-bottom: $default-spacing * 4;
  }

  .m-horizontal--xxx-large-gt-xs {
    margin-right: $default-spacing * 4;
    margin-left: $default-spacing * 4;
  }

  .m-around--xxx-large-gt-xs {
    margin: $default-spacing * 4;
  }
}

@include sm {
  .p-right--large-lt-md {
    padding-right: $default-spacing * 1.5;
  }

  .p-top--x-large-lt-md {
    margin-top: $default-spacing * 2;
  }

  .p-horizontal--large-lt-md {
    padding-left: $default-spacing * 1.5;
    padding-right: $default-spacing * 1.5;
  }

  .m-top--x-small-lt-md {
    margin-top: $default-spacing * .5;
  }

  .m-top--small-lt-md {
    margin-top: $default-spacing * .75;
  }

  .m-top--medium-lt-md {
    margin-top: $default-spacing;
  }

  .m-top--large-lt-md {
    margin-top: $default-spacing * 1.5;
  }

  .m-top--x-large-lt-md {
    margin-top: $default-spacing * 2;
  }

  .m-top--xx-large-lt-md {
    margin-top: $default-spacing * 3;
  }

  .m-left--medium-lt-md {
    margin-left: $default-spacing;
  }

  .m-right--small-lt-md {
    margin-right: $default-spacing * .75;
  }

  .m-right--medium-lt-md {
    margin-right: $default-spacing;
  }

  .m-right--large-lt-md {
    margin-right: $default-spacing * 1.5;
  }

  .m-horizontal--auto-lt-md {
    margin-left: auto;
    margin-right: auto;
  }
}

@include gt-sm {
  .p-top--none-gt-sm {
    padding-top: 0 !important;
  }

  .p-right--none-gt-sm {
    padding-right: 0 !important;
  }

  .p-bottom--none-gt-sm {
    padding-bottom: 0 !important;
  }

  .p-left--none-gt-sm {
    padding-left: 0 !important;
  }

  .p-vertical--none-gt-sm {
    padding-top: 0;
    padding-bottom: 0;
  }

  .p-horizontal--none-gt-sm {
    padding-right: 0;
    padding-left: 0;
  }

  .p-around--none-gt-sm {
    padding: 0;
  }

  .p-top--xxx-small-gt-sm {
    padding-top: $default-spacing * .125;
  }

  .p-right--xxx-small-gt-sm {
    padding-right: $default-spacing * .125;
  }

  .p-bottom--xxx-small-gt-sm {
    padding-bottom: $default-spacing * .125;
  }

  .p-left--xxx-small-gt-sm {
    padding-left: $default-spacing * .125;
  }

  .p-vertical--xxx-small-gt-sm {
    padding-top: $default-spacing * .125;
    padding-bottom: $default-spacing * .125;
  }

  .p-horizontal--xxx-small-gt-sm {
    padding-right: $default-spacing * .125;
    padding-left: $default-spacing * .125;
  }

  .p-around--xxx-small-gt-sm {
    padding: $default-spacing * .125;
  }

  .p-top--xx-small-gt-sm {
    padding-top: $default-spacing * .25;
  }

  .p-right--xx-small-gt-sm {
    padding-right: $default-spacing * .25;
  }

  .p-bottom--xx-small-gt-sm {
    padding-bottom: $default-spacing * .25;
  }

  .p-left--xx-small-gt-sm {
    padding-left: $default-spacing * .25;
  }

  .p-vertical--xx-small-gt-sm {
    padding-top: $default-spacing * .25;
    padding-bottom: $default-spacing * .25;
  }

  .p-horizontal--xx-small-gt-sm {
    padding-right: $default-spacing * .25;
    padding-left: $default-spacing * .25;
  }

  .p-around--xx-small-gt-sm {
    padding: $default-spacing * .25;
  }

  .p-top--x-small-gt-sm {
    padding-top: $default-spacing * .5;
  }

  .p-right--x-small-gt-sm {
    padding-right: $default-spacing * .5;
  }

  .p-bottom--x-small-gt-sm {
    padding-bottom: $default-spacing * .5;
  }

  .p-left--x-small-gt-sm {
    padding-left: $default-spacing * .5;
  }

  .p-vertical--x-small-gt-sm {
    padding-top: $default-spacing * .5;
    padding-bottom: $default-spacing * .5;
  }

  .p-horizontal--x-small-gt-sm {
    padding-right: $default-spacing * .5;
    padding-left: $default-spacing * .5;
  }

  .p-around--x-small-gt-sm {
    padding: $default-spacing * .5;
  }

  .p-top--small-gt-sm {
    padding-top: $default-spacing * .75;
  }

  .p-right--small-gt-sm {
    padding-right: $default-spacing * .75;
  }

  .p-bottom--small-gt-sm {
    padding-bottom: $default-spacing * .75;
  }

  .p-left--small-gt-sm {
    padding-left: $default-spacing * .75;
  }

  .p-vertical--small-gt-sm {
    padding-top: $default-spacing * .75;
    padding-bottom: $default-spacing * .75;
  }

  .p-horizontal--small-gt-sm {
    padding-right: $default-spacing * .75;
    padding-left: $default-spacing * .75;
  }

  .p-around--small-gt-sm {
    padding: $default-spacing * .75;
  }

  .p-top--medium-gt-sm {
    padding-top: $default-spacing;
  }

  .p-right--medium-gt-sm {
    padding-right: $default-spacing;
  }

  .p-bottom--medium-gt-sm {
    padding-bottom: $default-spacing;
  }

  .p-left--medium-gt-sm {
    padding-left: $default-spacing;
  }

  .p-vertical--medium-gt-sm {
    padding-top: $default-spacing;
    padding-bottom: $default-spacing;
  }

  .p-horizontal--medium-gt-sm {
    padding-right: $default-spacing;
    padding-left: $default-spacing;
  }

  .p-around--medium-gt-sm {
    padding: $default-spacing;
  }

  .p-top--large-gt-sm {
    padding-top: $default-spacing * 1.5;
  }

  .p-right--large-gt-sm {
    padding-right: $default-spacing * 1.5;
  }

  .p-bottom--large-gt-sm {
    padding-bottom: $default-spacing * 1.5;
  }

  .p-left--large-gt-sm {
    padding-left: $default-spacing * 1.5;
  }

  .p-vertical--large-gt-sm {
    padding-top: $default-spacing * 1.5;
    padding-bottom: $default-spacing * 1.5;
  }

  .p-horizontal--large-gt-sm {
    padding-right: $default-spacing * 1.5;
    padding-left: $default-spacing * 1.5;
  }

  .p-around--large-gt-sm {
    padding: $default-spacing * 1.5;
  }

  .p-top--x-large-gt-sm {
    padding-top: $default-spacing * 2;
  }

  .p-right--x-large-gt-sm {
    padding-right: $default-spacing * 2;
  }

  .p-bottom--x-large-gt-sm {
    padding-bottom: $default-spacing * 2;
  }

  .p-left--x-large-gt-sm {
    padding-left: $default-spacing * 2;
  }

  .p-vertical--x-large-gt-sm {
    padding-top: $default-spacing * 2;
    padding-bottom: $default-spacing * 2;
  }

  .p-horizontal--x-large-gt-sm {
    padding-right: $default-spacing * 2;
    padding-left: $default-spacing * 2;
  }

  .p-around--x-large-gt-sm {
    padding: $default-spacing * 2;
  }

  .p-top--xx-large-gt-sm {
    padding-top: $default-spacing * 3;
  }

  .p-right--xx-large-gt-sm {
    padding-right: $default-spacing * 3;
  }

  .p-bottom--xx-large-gt-sm {
    padding-bottom: $default-spacing * 3;
  }

  .p-left--xx-large-gt-sm {
    padding-left: $default-spacing * 3;
  }

  .p-vertical--xx-large-gt-sm {
    padding-top: $default-spacing * 3;
    padding-bottom: $default-spacing * 3;
  }

  .p-horizontal--xx-large-gt-sm {
    padding-right: $default-spacing * 3;
    padding-left: $default-spacing * 3;
  }

  .p-around--xx-large-gt-sm {
    padding: $default-spacing * 3;
  }

  .p-top--xxx-large-gt-sm {
    padding-top: $default-spacing * 4;
  }

  .p-right--xxx-large-gt-sm {
    padding-right: $default-spacing * 4;
  }

  .p-bottom--xxx-large-gt-sm {
    padding-bottom: $default-spacing * 4;
  }

  .p-left--xxx-large-gt-sm {
    padding-left: $default-spacing * 4;
  }

  .p-vertical--xxx-large-gt-sm {
    padding-top: $default-spacing * 4;
    padding-bottom: $default-spacing * 4;
  }

  .p-horizontal--xxx-large-gt-sm {
    padding-right: $default-spacing * 4;
    padding-left: $default-spacing * 4;
  }

  .p-around--xxx-large-gt-sm {
    padding: $default-spacing * 4;
  }

  .p-horizontal--xxxx-large-gt-sm {
    padding-right: $default-spacing * 5;
    padding-left: $default-spacing * 5;
  }

  .m-horizontal--auto-gt-sm {
    margin-left: auto;
    margin-right: auto;
  }

  .m-top--none-gt-sm {
    margin-top: 0 !important;
  }

  .m-right--none-gt-sm {
    margin-right: 0 !important;
  }

  .m-bottom--none-gt-sm {
    margin-bottom: 0 !important;
  }

  .m-left--none-gt-sm {
    margin-left: 0 !important;
  }

  .m-vertical--none-gt-sm {
    margin-top: 0;
    margin-bottom: 0;
  }

  .m-horizontal--none-gt-sm {
    margin-right: 0;
    margin-left: 0;
  }

  .m-top--xxx-small-gt-sm {
    margin-top: $default-spacing * .125;
  }

  .m-right--xxx-small-gt-sm {
    margin-right: $default-spacing * .125;
  }

  .m-bottom--xxx-small-gt-sm {
    margin-bottom: $default-spacing * .125;
  }

  .m-left--xxx-small-gt-sm {
    margin-left: $default-spacing * .125;
  }

  .m-vertical--xxx-small-gt-sm {
    margin-top: $default-spacing * .125;
    margin-bottom: $default-spacing * .125;
  }

  .m-horizontal--xxx-small-gt-sm {
    margin-right: $default-spacing * .125;
    margin-left: $default-spacing * .125;
  }

  .m-around--xxx-small-gt-sm {
    margin: $default-spacing * .125;
  }

  .m-top--xx-small-gt-sm {
    margin-top: $default-spacing * .25;
  }

  .m-right--xx-small-gt-sm {
    margin-right: $default-spacing * .25;
  }

  .m-bottom--xx-small-gt-sm {
    margin-bottom: $default-spacing * .25;
  }

  .m-left--xx-small-gt-sm {
    margin-left: $default-spacing * .25;
  }

  .m-vertical--xx-small-gt-sm {
    margin-top: $default-spacing * .25;
    margin-bottom: $default-spacing * .25;
  }

  .m-horizontal--xx-small-gt-sm {
    margin-right: $default-spacing * .25;
    margin-left: $default-spacing * .25;
  }

  .m-around--xx-small-gt-sm {
    margin: $default-spacing * .25;
  }

  .m-top--x-small-gt-sm {
    margin-top: $default-spacing * .5;
  }

  .m-right--x-small-gt-sm {
    margin-right: $default-spacing * .5;
  }

  .m-bottom--x-small-gt-sm {
    margin-bottom: $default-spacing * .5;
  }

  .m-left--x-small-gt-sm {
    margin-left: $default-spacing * .5;
  }

  .m-vertical--x-small-gt-sm {
    margin-top: $default-spacing * .5;
    margin-bottom: $default-spacing * .5;
  }

  .m-horizontal--x-small-gt-sm {
    margin-right: $default-spacing * .5;
    margin-left: $default-spacing * .5;
  }

  .m-around--x-small-gt-sm {
    margin: $default-spacing * .5;
  }

  .m-top--small-gt-sm {
    margin-top: $default-spacing * .75;
  }

  .m-right--small-gt-sm {
    margin-right: $default-spacing * .75;
  }

  .m-bottom--small-gt-sm {
    margin-bottom: $default-spacing * .75;
  }

  .m-left--small-gt-sm {
    margin-left: $default-spacing * .75;
  }

  .m-vertical--small-gt-sm {
    margin-top: $default-spacing * .75;
    margin-bottom: $default-spacing * .75;
  }

  .m-horizontal--small-gt-sm {
    margin-right: $default-spacing * .75;
    margin-left: $default-spacing * .75;
  }

  .m-around--small-gt-sm {
    margin: $default-spacing * .75;
  }

  .m-top--medium-gt-sm {
    margin-top: $default-spacing;
  }

  .m-right--medium-gt-sm {
    margin-right: $default-spacing;
  }

  .m-bottom--medium-gt-sm {
    margin-bottom: $default-spacing;
  }

  .m-left--medium-gt-sm {
    margin-left: $default-spacing;
  }

  .m-vertical--medium-gt-sm {
    margin-top: $default-spacing;
    margin-bottom: $default-spacing;
  }

  .m-horizontal--medium-gt-sm {
    margin-right: $default-spacing;
    margin-left: $default-spacing;
  }

  .m-around--medium-gt-sm {
    margin: $default-spacing;
  }

  .m-top--large-gt-sm {
    margin-top: $default-spacing * 1.5;
  }

  .m-right--large-gt-sm {
    margin-right: $default-spacing * 1.5;
  }

  .m-bottom--large-gt-sm {
    margin-bottom: $default-spacing * 1.5;
  }

  .m-left--large-gt-sm {
    margin-left: $default-spacing * 1.5;
  }

  .m-vertical--large-gt-sm {
    margin-top: $default-spacing * 1.5;
    margin-bottom: $default-spacing * 1.5;
  }

  .m-horizontal--large-gt-sm {
    margin-right: $default-spacing * 1.5;
    margin-left: $default-spacing * 1.5;
  }

  .m-around--large-gt-sm {
    margin: $default-spacing * 1.5;
  }

  .m-top--x-large-gt-sm {
    margin-top: $default-spacing * 2;
  }

  .m-right--x-large-gt-sm {
    margin-right: $default-spacing * 2;
  }

  .m-bottom--x-large-gt-sm {
    margin-bottom: $default-spacing * 2;
  }

  .m-left--x-large-gt-sm {
    margin-left: $default-spacing * 2;
  }

  .m-vertical--x-large-gt-sm {
    margin-top: $default-spacing * 2;
    margin-bottom: $default-spacing * 2;
  }

  .m-horizontal--x-large-gt-sm {
    margin-right: $default-spacing * 2;
    margin-left: $default-spacing * 2;
  }

  .m-around--x-large-gt-sm {
    margin: $default-spacing * 2;
  }

  .m-top--xx-large-gt-sm {
    margin-top: $default-spacing * 3;
  }

  .m-right--xx-large-gt-sm {
    margin-right: $default-spacing * 3;
  }

  .m-bottom--xx-large-gt-sm {
    margin-bottom: $default-spacing * 3;
  }

  .m-left--xx-large-gt-sm {
    margin-left: $default-spacing * 3;
  }

  .m-vertical--xx-large-gt-sm {
    margin-top: $default-spacing * 3;
    margin-bottom: $default-spacing * 3;
  }

  .m-horizontal--xx-large-gt-sm {
    margin-right: $default-spacing * 3;
    margin-left: $default-spacing * 3;
  }

  .m-around--xx-large-gt-sm {
    margin: $default-spacing * 3;
  }

  .m-top--xxx-large-gt-sm {
    margin-top: $default-spacing * 4;
  }

  .m-right--xxx-large-gt-sm {
    margin-right: $default-spacing * 4;
  }

  .m-bottom--xxx-large-gt-sm {
    margin-bottom: $default-spacing * 4;
  }

  .m-left--xxx-large-gt-sm {
    margin-left: $default-spacing * 4;
  }

  .m-vertical--xxx-large-gt-sm {
    margin-top: $default-spacing * 4;
    margin-bottom: $default-spacing * 4;
  }

  .m-horizontal--xxx-large-gt-sm {
    margin-right: $default-spacing * 4;
    margin-left: $default-spacing * 4;
  }

  .m-around--xxx-large-gt-sm {
    margin: $default-spacing * 4;
  }
}

@include gt-md {
  .m-left--small-gt-md {
    margin-left: $default-spacing * .75;
  }

  .m-right--x-small-gt-md {
    margin-right: $default-spacing * .5;
  }

  .m-right--large-gt-md {
    margin-right: $default-spacing * 1.5;
  }

  .p-bottom--xxxx-large-gt-md {
    padding-bottom: $default-spacing * 8;
  }
}
