.select-wrapper, .custom-select-wrapper {
  text-align: left;

  label.required::after {
    content: " *";
    color: $red;
  }

  .react-select-container {
    border: none;

    .group-styles {
      display: flex;
      align-items: 'center';
      justify-content: 'space-between';
      color: black;
      font-weight: 700;
    }

    &.react-select--is-disabled .react-select__control {
      color: $gray-1;
      background-color: $gray-3;
    }

    .react-select__placeholder {
      color: $gray-1;
    }
  }

  &.error .react-select__control, &.error select {
    border: $input-border-width solid $red;
    background-color: $light-pink;

    .react-select__placeholder {
      color: $red;
    }
  }

  select, .react-select__control {
    width: 100%;
    height: $input-height;
    min-height: $input-height;
    padding: $default-spacing * .25  $default-spacing * .25 $default-spacing * .25  $default-spacing * .5;
    background-color: $white;
    border: $input-border-width solid $gray-2;
    border-radius: $input-border-radius;
    overflow: hidden;
    cursor: pointer;
    @include placeholder-color {
      color: $gray-1;
    }

    input {
      height: auto;
      min-height: auto;
    }

    .react-select__value-container {
      padding: 0;
    }

    &.react-select__control--is-focused {
      border-color: $black;
      box-shadow: none;
    }

    &:invalid, option:disabled {
      color: $gray-1;
    }
  }

  .react-select__menu {
    margin-top: $default-spacing * .25;
    margin-bottom: $default-spacing * .25;
    border: $input-border-width solid $gray-2;
    background-color: $white;
    border-radius: $input-border-radius;
    box-shadow: $box-shadow;
    z-index: 15;
    overflow: hidden;
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select-container--createable .react-select__option:last-child:not(.react-select__option--is-selected) {
    font-weight: 600;
    color: $pacific;
    background-color: $white;
  }

  .react-select__option--is-selected {
    color: $white;
    background-color: $hue-0;
  }

  .react-select__option--is-focused:not(.react-select__option--is-selected) {
    background-color: $gray-3;
    cursor: pointer;
  }

  // Border radius for first and last option when not grouped
  .react-select__option--is-selected, .react-select__option--is-focused:not(.react-select__option--is-selected) {
    &:first-child {
      border-radius: $input-border-radius $input-border-radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $input-border-radius $input-border-radius;
    }
  }

  .react-select__group {
    .react-select__option--is-selected, .react-select__option--is-focused:not(.react-select__option--is-selected) {
      &:first-child {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0;
      }
    }
  }

  .react-select__indicator {
    color: $black;
    padding: $default-spacing * .5 $default-spacing * .75;
  }

  .react-select__multi-value {
    background-color: $gray-3;
    border: $input-border-width solid $gray-2;
    border-radius: $border-radius-sm;
  }

  .react-select__multi-value__remove {
    &:hover {
      color: $gray-1;
      background-color: $gray-3;
    }
  }
}

// Custom index for selects in dialogs
.dialog .react-select__control  .react-select__menu {
  z-index: 101;
}

.custom-select-wrapper.bed-select {
  .react-select__control {
    padding: 0;

    .react-select__value-container {
      height: 100%;

      .react-select__single-value {
        margin-left: 0;
      }

      .react-select__placeholder {
        margin-left: $default-spacing * .5;
      }
    }
  }

  .react-select__option--is-selected {
    color: $black;
    background-color: transparent;

    &:hover {
      background-color: $light-blue;
      cursor: pointer;
    }
  }

  .react-select__option--is-focused {
    background-color: $light-blue;
  }

  .react-select__option {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: $gray-2;
    padding: 0;

    &:hover {
      background-color: $light-blue;
    }
  }
}

// Google Places Autocomplete
.pac-container {
  border: $input-border-width solid $gray-2;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  margin-top: $default-spacing * .25;

  .pac-item {
    font-weight: normal;
    color: $black;
    font-size: 14px;
    border-radius: $border-radius-sm;
    padding: $default-spacing * .5;
    letter-spacing: -0.1px;
    width: 100%;
    background-color: $white;
    outline: none;

    :not(:first-child) {
      border-top-color: $gray-2;
    }

    &:hover, &.pac-item-selected {
      cursor: pointer;
      background-color: $gray-3;
    }
  }

  .pac-item-query {
    font-size: 14px;
    color: $black;
  }

  .pac-icon {
    display: none !important;
  }
}

.hdpi.pac-logo::after {
  position: absolute;
  bottom: 0;
  right: $default-spacing * .5;
  width: 64px;
  background-size: 60px 7px;
}
