/* Edit Listing */
.listing-edit-tabs {
  .title-row {
    z-index: 100;
    height: 65px;
    @include gt-sm {
      height: $header-height-gt-xs;
    }

    &:not(.active-tab-0) {
      height: 129px;
      @include gt-sm {
        height: $header-height-gt-xs;
      }
    }
  }

  .tabs-wrapper {
    height: 65px + 60px;
    white-space: nowrap;
    overflow-x: auto;
    padding-top: 65px;
    max-width: 100vw;

    @include gt-sm {
      height: $header-height-gt-xs + 60px;
      padding-top: $header-height-gt-xs;
    }

    &:not(.active-tab-0) {
      height: 129px + 60px;
      padding-top: 129px;
      @include gt-sm {
        height: $header-height-gt-xs + 60px;
        padding-top: $header-height-gt-xs;
      }
    }
  }

  .listing-edit-tab.disabled h5 {
    font-weight: 400;
  }
}

.listing-edit-component {
  @include gt-xs {
    padding-bottom: $default-spacing * 5;
  }
}

.listing-edit-bottom-actions {
  @include gt-xs {
    position: fixed;
    bottom: 0;
  }
}

.listing-edit-amenity {
  width: 100%;
  @include gt-xs {
    width: 300px;
  }
}

.upload-thumbnail {
  width: 265px;

  @include xs {
    width: 100%;
  }

  .upload-thumbnail--image {
    height: 172px;

    @include xs {
      width: 100%;
    }
  }

  .toolbar {
    opacity: 0;
    transition: opacity $transition-duration $cubic-bezier;
  }

  &:hover .toolbar {
    opacity: 1;
  }
}
