.rc-tooltip {
  opacity: 1 !important;
  max-width: 250px;
  z-index: 1500 !important;

  .rc-tooltip-inner {
    font-family: Soleil, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.3px;
  }
}
