.message--margin-left {
  @include xs {
    margin-left: 48px;
  }

  @include gt-xs {
    margin-left: 60px;
  }
}

.message-image {
  max-width: 100%;
  border-radius: $border-radius-lg;
}

.message-form {
  width: 100%;

  @include gt-sm {
    width: calc(50% - 2px);
  }
}

.event-message {
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background: $gray-2;
    transition: width $transition-duration $cubic-bezier $transition-delay;
    transform: translateY(-50%);
  }

  &::before {
    text-align: right;
    margin-left: -30px;
  }

  &::after {
    text-align: left;
    margin-left: 10px;
  }

  &::after,
  &::before {
    width: 20px
  }
}
