.tiles-container {
  @include sm {
    margin-left: -$default-spacing * 1.5;
    margin-bottom: -$default-spacing * 1.5;
  }

  @include gt-sm {
    margin-left: -$default-spacing * 1.25;
    margin-bottom: -$default-spacing * 1.25;
  }

  .tile {
    @include sm {
      padding: 0 0 $default-spacing * 1.5 $default-spacing * 1.5;
    }

    @include gt-sm {
      padding: 0 0 $default-spacing * 1.25 $default-spacing * 1.25;
    }
  }
}

.masonry-two-columns-gt-xs {
  @include gt-xs {
    column-count: 2;
    column-gap: 0px;

    div {
      break-inside: avoid;
    }
  }
}

.dash-separator:not(:first-child) {
  position: relative;
  margin-left: $default-spacing * .25;
  padding-left: $default-spacing * 1.25;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: $default-spacing;
    height: 1px;
    background-color: currentColor;
    transform: translateY(-50%);
  }
}

.dot-separator {
  position: relative;
  margin-right: $default-spacing * .25;
  padding-right: $default-spacing * .5;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 3px;
    height: 3px;
    background-color: currentColor;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.dot-separator--gt-xs {
  @include gt-xs {
    position: relative;
    margin-right: $default-spacing * .25;
    padding-right: $default-spacing * .5;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 3px;
      height: 3px;
      background-color: currentColor;
      border-radius: 50%;
      transform: translateY(-50%);
    }
  }
}
