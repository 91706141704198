.headshot-stack {
  display: inline-flex;

  > :first-child {
    z-index: 10;
  }
}

.headshot--offset-top {
  display: inline-flex;
  margin-top: $default-spacing * .75;
  margin-left: -$default-spacing;
}
