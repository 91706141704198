$timeline-num-line-items: 10;
$timeline-item-transition-delay: 0.5s;

.about-hero {
  background-image: url($image-url + 'background/rectangle-cherry-2.svg');
  background-position: bottom center;
}

.timeline {
  @include sm {
    max-width: 540px;
    margin: ($default-spacing * 3) auto 0 auto;
  }
  @include gt-sm {
    margin: ($default-spacing * 4) auto 0 auto;
  }
}

.timeline-connector {
  position: absolute;
  width: 100%;
  height: auto;
  top: 64px;
  left: 0;
  right: 0;
  stroke-width: 12px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 4250;
  stroke-dashoffset: 4250;
  overflow: visible;
  animation: draw ($timeline-num-line-items * $timeline-item-transition-delay - $timeline-item-transition-delay) $timeline-item-transition-delay * 3 1 $linear forwards;
  @include xs-sm {
    top: 84px;
    stroke-dasharray: 4450;
    stroke-dashoffset: 4450;
  }
  @include gt-sm {
    top: 52px;
    stroke-dasharray: 4650;
    stroke-dashoffset: 4650;
  }

  path {
    fill: transparent;
    stroke: $hue-0;
    vector-effect: non-scaling-stroke;
    transition: stroke $transition-duration $cubic-bezier;
  }
}

.timeline-bg {
  @include gt-xs {
    background-color: $white;
  }
}

.timeline-jack {
  .fade-slide-up:first-child {
    animation-delay: $timeline-item-transition-delay;
  }

  .fade-slide-up:last-child {
    animation-delay: $timeline-item-transition-delay * 2;
  }

  img {
    width: 126px !important;
    @include gt-sm {
      width: 168px !important;
    }
  }
}

.timeline-launch {
  .fade-slide-up:first-child {
    animation-delay: $timeline-item-transition-delay * 5;

    img {
      width: 98px !important;
      @include gt-sm {
        width: 130px !important;
      }
    }
  }

  .fade-slide-up:last-child {
    animation-delay: $timeline-item-transition-delay * 4;
  }
}

.timeline-unite {
  .fade-slide-up:first-child {
    animation-delay: $timeline-item-transition-delay * 7;

    img {
      width: 128px !important;
      @include gt-sm {
        width: 170px !important;
      }
    }
  }

  .fade-slide-up:last-child {
    animation-delay: $timeline-item-transition-delay * 6;
  }
}

.timeline-full-time {
  @include gt-sm {
    margin-top: $default-spacing * 9;
  }

  .fade-slide-up:first-child {
    animation-delay: $timeline-item-transition-delay * 8;

    img {
      width: 160px !important;
      @include gt-sm {
        width: 215px !important;
      }
    }
  }

  .fade-slide-up:last-child {
    animation-delay: $timeline-item-transition-delay * 9;
  }
}

.timeline-graduate {
  .fade-slide-up:first-child {
    animation-delay: $timeline-item-transition-delay * 10;
  }

  .fade-slide-up:last-child {
    animation-delay: $timeline-item-transition-delay * 11;
  }

  img {
    width: 105px !important;
    @include gt-sm {
      width: 140px !important;
    }
  }
}

.timeline-updates {
  animation-delay: $timeline-item-transition-delay * 12;
  @include xs {
    margin-top: $default-spacing * 6;
  }
  @include xs-sm {
    margin-top: $default-spacing * 5;
  }
  @include gt-sm {
    top: -62px;
  }

  img {
     width: 116px !important;
    @include gt-sm {
      width: 155px !important;
    }
  }
}

.crosby {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;

  &.visible {
    animation: skate 5s $cubic-bezier 1 forwards;
  }
}

.about-faq {
  background-image: url($image-url + 'background/rectangle-blue-2.svg');
  background-position: top center;
}
