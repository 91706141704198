.business-hero {
  background-position: center bottom;
  background-image: url($image-url + 'background/rectangle-orange-2.svg');
}

.university-hero {
  background-position: center bottom;
  background-image: url($image-url + 'background/rectangle-blue-6.svg');
}

.partner-hero {
  background-position: center bottom;
  background-image: url($image-url + 'background/rectangle-green-3.svg');
}

.business-footer, .university-footer, .partner-footer {
  background-position: center top;
  background-image: url($image-url + 'background/rectangle-blue-5.svg');
}

.business-custom-hero {
  background-position: center bottom;
  background-image: url($image-url + 'background/rectangle-gray-1.svg');
}

.partner-custom-hero {
  background-color: $light-blue;
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url($image-url + 'background/apartments-for-rent-on-hills@2x.jpg');
  @include gt-xs {
    background-size: cover;
  }
}

.meal-on-us {
  background-position: center center;
  background-image: url($image-url + 'background/oval-blue-1.svg');
  @include gt-xs {
    background-size: 100%;
  }
}

.no-housemates-hero {
  background-image: url($image-url + 'background/rectangle-light-blue-2.svg');

  @include xs {
    background-image: url($image-url + 'background/rectangle-light-blue-5.svg');
    min-height: 420px;
  }

  @include gt-xs {
    min-height: 405px;
  }

  @include gt-sm {
    min-height: 422px;
  }
}

.top-sights-attractions-hero {
  @include xs {
    background-image: url($image-url + 'background/rectangle-light-blue-3.svg');
  }

  @include gt-xs {
    background-image: url($image-url + 'background/rectangle-light-blue-4.svg');
  }

  @include gt-sm {
    background-image: url($image-url + 'background/rectangle-light-blue-2.svg');
  }
}

.city-map-image {
  position: relative;
  width: 100%;
  height: 500px;
  @include xs {
    height: 265px;
  }
}

.city-attraction {
  @include gt-sm {
    min-height: 550px;
    position: relative;
  }
}

.city-attraction-image {
  @include gt-sm {
    width: 65%;
  }
}

.city-attraction-description {
  @include xs {
    margin-top: -$default-spacing * 2;
    width: 90%;
  }

  @include gt-xs {
    margin-top: -$default-spacing * 4;
    width: 90%;
  }

  @include gt-sm {
    margin-top: $default-spacing * 1.5;
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    transform: translateY(-50%);
  }
}

.direct-booking-pro-hero {
  @include xs {
    background: url($image-url + 'background/splat-blue-2.svg') no-repeat 140% 50%/50%, url($image-url + 'background/splat-blue-1.svg') no-repeat -20% 64%/40%;
  }
  @include gt-xs {
    background: url($image-url + 'background/splat-blue-2.svg') no-repeat 140% 15%/40%, url($image-url + 'background/splat-blue-1.svg') no-repeat -20% 75%/40%;
  }
}

.direct-booking-pro-screenshots {
  max-width: 970px;
}

.direct-booking-pro-testimonials {
  background: url($image-url + 'background/rectangle-gray-6.svg') no-repeat center center/cover;
}

.direct-booking-pro-mobile {
  background: url($image-url + 'background/rectangle-black.svg') no-repeat center center/cover;

  .image-container {
    width: 283px;
    height: 532px;
    margin: 0 auto;
    overflow-y: scroll;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-thick;

    img {
      border-radius: $border-radius-lg;
    }
  }
}

.direct-booking-pro-bottom {
  background: url($image-url + 'background/rectangle-blue-splat.svg') no-repeat top center/cover;
}

.scroll-indicator {
  animation: scrollIndicatorBounce 1s $ease-in-out infinite;
}
