// Colors
$pacific: #185C97;
$hue-0: #062E51;
$hue-1: #357EAF;
$hue-2: #85B4D3;

$black: #212121;
$black-transparent: rgba(0,0,0,0.6);
$black-gradient-left: linear-gradient(to left, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
$black-gradient-right: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
$gray-0: #696969;
$gray-1: #B4B1AC;
$gray-2: #D5D5D5;
$gray-2-transparent: rgba($gray-2, .5);
$gray-3: #F7F7F7;
$white: #FFFFFF;
$white-transparent: rgba(254, 252, 251, 0.9);

$raspberry: #9B3060;
$red: #D12B2B;
$light-pink: #FFE1E0;
$green: #12442F;
$medium-green: #43866A;
$light-green: #C8DFD5;
$orange: #EF8032;
$pink: #FF8E87;
$light-blue: #E6F1F7;
$yellow: #FFB300;
$light-yellow: #FFEABA;

$facebook-color: #3B5998;
$facebook-color-hover: #4568B2;
$messenger-color: #0080FF;
$messenger-color-hover: #0084FF;
$twitter-color: #059FF5;
$twitter-color-hover: #1DA1F2;
$linkedin-color: #0077b5;
$dribbble-color: #EA4C89;
$google-color: #4081ED;


// Color classes
.pacific {
  color: $pacific;
}
.hue-0 {
  color: $hue-0;
}
.hue-1 {
  color: $hue-1;
}
.hue-2 {
  color: $hue-2;
}
.black {
  color: $black;
}
.gray-0 {
  color: $gray-0;
}
.gray-1 {
  color: $gray-1;
}
.gray-2 {
  color: $gray-2;
}
.white {
  color: $white;
}
.light-blue {
  color: $light-blue;
}
.red {
  color: $red;
}
.medium-green {
  color: $medium-green;
}
.orange {
  color: $orange;
}
.raspberry {
  color: $raspberry;
}
.yellow {
  color: $yellow;
}
.light-yellow {
  color: $light-yellow;
}
.facebook-color {
  color: $facebook-color;
}
.linkedin-color {
  color: $linkedin-color;
}
.twitter-color {
  color: $twitter-color;
}
.dribbble-color {
  color: $dribbble-color;
}
.transparent {
  color: transparent;
}
.bg--transparent {
  background-color: transparent;
}
.bg--black {
  background-color: $black;
}
.bg--gray-0 {
  background-color: $gray-0;
}
.bg--gray-1 {
  background-color: $gray-1;
}
.bg--gray-2 {
  background-color: $gray-2;
}
.bg--gray-3 {
  background-color: $gray-3;
}
.bg--white {
  background-color: $white;
}
.bg--white-transparent {
  background-color: $white-transparent;
}
.bg--pacific {
  background-color: $pacific;
}
.bg--hue-0 {
  background-color: $hue-0;
}
.bg--hue-1 {
  background-color: $hue-1;
}
.bg--raspberry {
  background-color: $raspberry;
}
.bg--red {
  background-color: $red;
}
.bg--light-pink {
  background-color: $light-pink;
}
.bg--medium-green {
  background-color: $medium-green;
}
.bg--light-green {
  background-color: $light-green;
}
.bg--yellow {
  background-color: $yellow;
}
.bg--light-yellow {
  background-color: $light-yellow;
}
.bg--orange {
  background-color: $orange;
}
.bg--light-blue {
  background-color: $light-blue;
}
.tag-waiting, .tag-created, .tag-pending, .tag-processing, .tag-refund_processing, .tag-pre_processing, .tag-refunded, .tag-light-yellow {
  background-color: $light-yellow;
}
.tag-failed {
  color: $white;
  background-color: $red;
}
.tag-succeeded, .tag-paid, .tag-light-green {
  background-color: $light-green;
}
.tag-gray {
  background-color: $gray-2;
}
.tag-light-blue {
  background-color: $light-blue;
}
.tag-white {
  background-color: $white;
}
