.relative {
  position: relative;
}

.relative-gt-sm {
  @include gt-sm {
    position: relative;
  }
}

.fixed {
  position: fixed;
}

.fixed-gt-xs {
  @include gt-xs {
    position: fixed;
  }
}

.fixed-xs {
  @include xs {
    position: fixed;
  }
}

.fixed-sm {
  @include sm {
    position: fixed;
  }
}

.sticky {
  position: sticky;
}

.sticky-gt-sm {
  @include gt-sm {
    position: sticky;
  }
}

.absolute {
  position: absolute;
}

.a--top {
  top: 0;
}

.a--top-lt-sm {
  @include xs {
    top: 0;
  }
}

.a--top-lt-md {
  @include sm {
    top: 0;
  }
}

.a--top-xs-negative {
  top: -$default-spacing / 4;
}

.a--top-100 {
  top: 100%;
}

.a--bottom {
  bottom: 0;
}

.a--bottom-gt-sm {
  @include gt-sm {
    bottom: 0;
  }
}

.a--right {
  right: 0;
}

.a--right-xs {
  right: 1px;
}

.a--right-lg-negative {
  right: -$default-spacing * 2;
  @include gt-xs {
    right: -$default-spacing * 3;
  }
}

.a--right-lt-md {
  @include sm {
    right: 0;
  }
}

.a--left {
  left: 0;
}

.a--left-gt-sm {
  @include gt-sm {
    left: 0;
  }
}

.a--vertical {
  top: 50%;
  transform: translateY(-50%);
}

.transform-center {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.transform-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.transform-center-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical-top {
  vertical-align: top;
}

.vertical-middle {
  vertical-align: middle;
}

.z--1 {
  z-index: 1;
}

.z--5 {
  z-index: 5;
}

.z--10 {
  z-index: 10;
}

.z--100 {
  z-index: 100;
}

.z--110 {
  z-index: 110 !important;
}

.z--1400 {
  z-index: 1400 !important;
}

.z--1500 {
  z-index: 1500;
}

.sixteen-nine {
  @include aspect-ratio(16,9);
}

.three-two {
  @include aspect-ratio(3,2);
}

.cover {
  object-fit: cover;
  object-position: 50% 50%;
}
