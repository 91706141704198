.button-wrapper {
  button {
    font-weight: 700;
    cursor: pointer;
    width: inherit;
    height: inherit;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    background-color: transparent;
    padding: 0;
    border: none;

    &:not(.no-transition) {
      transition: color $transition-duration $cubic-bezier, background-color $transition-duration $cubic-bezier, border-color $transition-duration $cubic-bezier;
    }

    &:disabled {
      cursor: default;
    }
  }

  &:not(.link):not(.button-round):not(.no-border-radius) button {
    border-radius: $button-border-radius;
  }

  &.primary button, &.secondary button, &.tertiary button, &.social button {
    height: $button-height;
    min-height: $button-height;
    padding: 0 16px;

    &.focus-visible {
      border: 1px solid $black;
      outline: none;
    }
  }

  &.primary {
    button {
      color: $white;
      background-color: $pacific;

      &:disabled {
        background-color: $hue-2;
      }
    }

    &:not(.hover-none) button:not([disabled]):hover {
      background-color: $hue-1;
    }

    &:not(.hover-none) button:not([disabled]):active {
      background-color: $hue-0;
    }
  }

  &.secondary, &.button-round {
    button {
      color: $pacific;
      border: 2px solid $pacific;

      &:disabled {
        color: $gray-2;
        border-color: $hue-2;
      }
    }

    button:not(.bg--transparent) {
      background-color: $white;
    }

    &:not(.hover-none) button:not([disabled]):hover {
      color: $hue-1;
      border-color: $hue-1;
    }
  }

  &.tertiary {
    button {
      display: flex;
      align-items: center;
      color: $hue-0;
      border: 2px solid $hue-0;
      background-color: $white;

      &:disabled {
        background-color: $gray-3;
      }
    }

    &:not(.hover-none):not(.active) button:not([disabled]):hover {
      background-color: $light-blue;
    }

    &.active button {
      color: $pacific;
      background-color: $light-blue;
      border-color: $pacific;
    }
  }

  &.paginate-link {
    button {
      width: 100%;
    }

    &.active {
      color: $white;
      background-color: $pacific;
      cursor: default;
      border: none;
    }

    &:not(.active) {
      color: $pacific;
      background-color: transparent;
      border: none;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid $pacific;
      }
    }
  }

  &.button-round button {
    display: block;
    line-height: 1;
    border-radius: 50%;
    border-width: 2px;
    height: 100%;
    padding: 0;

    &:disabled {
      color: $gray-2;
      border-color: $gray-2;
    }
  }

  &.button-ellipsis button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &.button-minus button {
    &::after {
      content: '-';
      line-height: 1;
      padding: 0 0 1px 1px;
    }
  }

  &.button-plus button {
    &::after {
      content: '+';
      line-height: 1;
      padding: 0 0 1px 1px;
    }
  }

  &.link {
    button {
      position: relative;
      color: inherit;
      background: none;
      font-size: inherit;
      padding: 0;
      overflow: visible;
      letter-spacing: -0.3px;
      vertical-align: inherit;
      font-weight: inherit;

      &.focus-visible {
        border: 1px solid $black;
        outline: none;
      }
    }


    &.underline button {
      text-decoration: underline;
    }

    &:hover:not(.hover-none) button {
      text-decoration: underline;
    }
  }

  &.button-icon {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      padding: 0;
      border-radius: 0 !important;
    }

    &:not(.hover-none):not(.primary):not(.secondary):not([disabled]) button {
      background: none;
    }
  }

  &.center-items button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &.spread-items button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &.signup-button {
    button {
      background-color: $white;
      color: $hue-0;
      border: 2px solid $hue-0;

      &:hover {
        background-color: $hue-0;
        color: $white;
      }
    }
  }

  &.social button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    padding-left: $default-spacing * .25;
    padding-right: $default-spacing * .25;

    .icon {
      height: $button-height - ($default-spacing * .5);
      width: $button-height - ($default-spacing * .5);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      border-radius: $border-radius-md;
    }
  }

  &.google-button button {
    border: 1px solid $google-color;
    background-color: $google-color;
  }

  &.facebook-button button {
    color: $white;
    border: 1px solid $facebook-color;
    background-color: $facebook-color;

    .icon {
      fill: $facebook-color;
    }

    &:hover, &:focus {
      border-color: $facebook-color-hover;
      background-color: $facebook-color-hover !important;
    }
  }

  &.messenger-button button {
    color: $white;
    border: 1px solid $messenger-color;
    background-color: $messenger-color;

    &:hover, &:focus {
      border-color: $messenger-color-hover;
      background-color: $messenger-color-hover !important;
    }
  }

  &.twitter-button button {
    color: $white;
    border: 1px solid $twitter-color;
    background-color: $twitter-color;

    &:hover, &:focus {
      border-color: $twitter-color-hover;
      background-color: $twitter-color-hover !important;
    }
  }
}

a.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: $button-height;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.1px;
  line-height: 48px;
  padding: 0 16px;
  text-align: center;
  border: none;
  border-radius: $button-border-radius;
  white-space: nowrap;
  transition: color $transition-duration $cubic-bezier, background-color $transition-duration $cubic-bezier, border-color $transition-duration $cubic-bezier;

  &.primary {
    color: $white;
    background-color: $pacific;

    &:not(.hover-none):hover {
      background-color: $hue-1;
    }

    &:not(.hover-none):not([disabled]):active {
      background-color: $hue-0;
    }
  }

  &.secondary {
    color: $pacific;
    background-color: $white;
    border: 2px solid $pacific;
    line-height: 44px;

    &:disabled {
      background-color: $gray-2;
    }

    &:not(.hover-none) button:not([disabled]):hover {
      color: $hue-1;
      border-color: $hue-1;
    }
  }
}
