$input-range-slider-track-height: 12px;
.input-range__slider {
  background: $white;
  border: 1px solid $gray-1;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: $default-spacing;
  margin-left: -$default-spacing * .5;
  margin-top: -$default-spacing * .5 - $input-range-slider-track-height * .5;
  outline: none;
  position: absolute;
  top: 50%;
  width: $default-spacing;
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: $gray-1;
  border: 1px solid $gray-1;
  box-shadow: none;
  transform: none;
}

.input-range__label {
  color: $gray-1;
  font-size: 14px;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -14px;
  position: absolute;
}

.input-range__label--min {
  display: none;
}

.input-range__label--max {
  display: none;
}

.input-range__label--value {
  display: none;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: $gray-2;
  border-radius: $border-radius-sm;
  cursor: pointer;
  display: block;
  height: $input-range-slider-track-height;
  position: relative;
}

.input-range--disabled .input-range__track {
  background: $white;
}

.input-range__track--background {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.input-range__track--active {
  background: $pacific;
}

.input-range {
  height: $default-spacing;
  position: relative;
  width: 100%;
}
